/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { ComponentPropsWithoutRef, useState } from 'react';

import { observer } from 'mobx-react';

import PersonIcon from '@mui/icons-material/Person';
import { Button, Menu, MenuItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { useServices } from 'services';

import { generateLoginURL } from 'utils/routes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  menuPaper: {
    maxWidth: 250,
  },
}));

export type AccountMenuProps = ComponentPropsWithoutRef<'div'>;

export const AccountMenu = observer(({ ...props }: AccountMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const classes = useStyles(props);
  const { authService } = useServices();
  const { accountDetails } = authService;

  useEffect(() => {
    authService.fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only execute on first render

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleCloseMenu();
    authService.logout(); // Call the airflow logout
  };

  if (!accountDetails) {
    return <LoginButton />;
  }

  return (
    <div className={classes.root}>
      <Button startIcon={<PersonIcon />} onClick={handleOpenMenu} sx={{ textTransform: 'none' }}>
        {accountDetails?.name}
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => handleCloseMenu()}
        anchorEl={anchorEl}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.menuPaper,
        }}
        PaperProps={{
          style: { minWidth: anchorEl?.clientWidth },
        }}
      >
        <MenuItem onClick={() => handleLogout()}>Log Out</MenuItem>
      </Menu>
    </div>
  );
});

const LoginButton = () => {
  return (
    <Button startIcon={<PersonIcon />} href={generateLoginURL()} sx={{ textTransform: 'none' }}>
      Log In
    </Button>
  );
};
