import { observer } from 'mobx-react';

import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormGroup, FormLabel, styled } from '@mui/material';

import { useServices } from 'services';

import { ProcessType } from 'utils/constants';

import { useStyles } from '../definitions/styles';
import { FilterFieldProps } from '../definitions/types';

const FormGroupOutlined = styled(FormGroup)`
  border: 1px solid #757575;
  border-radius: 4px;
  padding: 10px;
`;
const FormLabelOutlined = styled(FormLabel)`
  background-color: #4c4c4c;
  padding: 0px 4px 0px 4px;
  position: absolute;
`;

export const PipelineTypeFilter = observer((props: FilterFieldProps<CheckboxProps['onChange']>) => {
  const { onChange } = props;
  const classes = useStyles(props);
  const { processingService } = useServices();
  const filters = processingService.currentModeFilters;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <FormLabelOutlined className={classes.formBorder}>Pipeline Type</FormLabelOutlined>
      <FormGroupOutlined row>
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.processType.has(ProcessType.INGEST)}
              onChange={onChange}
              name={ProcessType.INGEST}
              value={ProcessType.INGEST}
              style={{ color: '#ffffff' }}
            />
          }
          label="Ingest"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.processType.has(ProcessType.VAP)}
              onChange={onChange}
              name={ProcessType.VAP}
              value={ProcessType.VAP}
              style={{ color: '#ffffff' }}
            />
          }
          label="VAP"
        />
      </FormGroupOutlined>
    </FormControl>
  );
});
