import React, { useState } from 'react';

import MenuBookIcon from '@mui/icons-material/MenuBook';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';

import { AnchorPoints } from 'utils/constants';

const ExternalLinkMenu = (): React.ReactElement | null => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex">
      <Button
        onClick={handleOpenMenu}
        sx={{
          minWidth: 'auto',
          padding: '10px',
        }}
      >
        <MenuBookIcon sx={{ width: 20 }} />
      </Button>
      <Menu
        id="preference-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        {...AnchorPoints.BottomRight}
      >
        <MenuItem
          sx={{ justifyContent: 'space-between' }}
          component="a"
          href="/apidocs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography sx={{ paddingRight: 3 }}>REST API</Typography>
          <OpenInNewIcon />
        </MenuItem>
        <MenuItem
          sx={{ justifyContent: 'space-between' }}
          component="a"
          href="/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography sx={{ paddingRight: 3 }}>Airflow Dashboard</Typography>
          <OpenInNewIcon />
        </MenuItem>
        <MenuItem
          sx={{ justifyContent: 'space-between' }}
          component="a"
          href="https://code.arm.gov/armflow/armflow-docs/-/blob/main/ARMFLOW_ADI_DEV_README.md"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography sx={{ paddingRight: 3 }}>Guide for VAP/Ingest Developers</Typography>
          <OpenInNewIcon />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ExternalLinkMenu;
