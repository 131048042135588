/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import ArmflowTheme from 'theme';

import { IconBullet } from 'components/common/IconButton';
import RunModeIcon from 'components/icons/RunModeIcon';
import { ProcessStateLegendItem } from 'utils/types';

interface DescriptionEntryProps {
  entry: ProcessStateLegendItem;
}

export const DescriptionEntry = ({ entry }: DescriptionEntryProps) => {
  const { state, label, description } = entry;
  return (
    <div>
      <IconBullet css={labelTextStyle}>
        <RunModeIcon runMode={state} />
        {label}
      </IconBullet>
      <div css={{ paddingLeft: '2.5rem' }}>{description}</div>
    </div>
  );
};
const labelTextStyle = css`
  font-weight: ${ArmflowTheme.typography.fontWeightSemiBold};
  align-items: center;
`;
