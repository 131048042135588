import React from 'react';

import { observer } from 'mobx-react';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, Collapse, Fade, Paper, Stack, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import { DRAWER_CLOSED_WIDTH, DRAWER_WIDTH, FILTER_PANEL_COLOR } from 'utils/constants';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    collapse: {
      width: DRAWER_WIDTH,
    },
    collapseButton: {
      padding: theme.spacing(2),
      minWidth: 0,
    },
    collapsePaper: {
      width: DRAWER_WIDTH,
      backgroundColor: FILTER_PANEL_COLOR,
    },
    collapseStack: {
      backgroundColor: FILTER_PANEL_COLOR,
    },
    fade: {
      backgroundColor: FILTER_PANEL_COLOR,
    },
  });

export interface IAppProps extends WithServicesProps {
  children?: React.ReactNode;
  classes: {
    root: string;
    collapse: string;
    collapseButton: string;
    collapsePaper: string;
    collapseStack: string;
    fade: string;
  };
}

class Drawer extends React.Component<IAppProps, { [key: string]: unknown }> {
  resetFilters = () => {
    const { processingService } = this.props.services;
    const filters = processingService.currentModeFilters;

    filters.reset();
    processingService.refresh();
  };

  renderFilters() {
    const { classes, services } = this.props;
    const { actionBarService, drawerService } = services;
    const drawer_open = drawerService.drawerOpen;

    const arrow_icon = drawer_open ? <ArrowRightIcon /> : <ArrowLeftIcon />;
    const tooltip_text = drawer_open ? 'Close filters' : 'Open filters';

    return (
      <Paper className={classes.collapsePaper}>
        <Stack
          className={classes.collapseStack}
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
          paddingRight={'2rem'}
        >
          <div>
            <Tooltip title={tooltip_text} placement="left">
              <Button
                className={classes.collapseButton}
                onClick={(e) => {
                  drawerService.toggleDrawer(!drawer_open);
                  actionBarService.refreshLabelWidth();
                }}
              >
                {arrow_icon}
              </Button>
            </Tooltip>
          </div>
        </Stack>

        <Fade className={classes.fade} in={drawer_open} timeout={0}>
          <Paper className={classes.collapsePaper}>{this.props.children}</Paper>
        </Fade>
      </Paper>
    );
  }

  render() {
    const { classes, services } = this.props;
    const { drawerService } = services;

    // TODO: (Elvis) Remove drawer service after setting up Timelines (only Drawer.tsx knows the drawer state now)
    const drawer_open = drawerService.drawerOpen;

    return (
      <div className={classes.root}>
        <Collapse
          in={drawer_open}
          className={classes.collapse}
          collapsedSize={DRAWER_CLOSED_WIDTH}
          orientation="horizontal"
          timeout={0}
        >
          {this.renderFilters()}
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(withServices(observer(Drawer)));
