import React from 'react';

import { observer } from 'mobx-react';

import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';

import clsx from 'clsx';
import _ from 'lodash';

import { useStyles } from '../definitions/styles';
import { RadioItem } from '../definitions/types';
import { BorderedFilterFieldGroup } from './BorderedFilterFieldGroup';

type RadioFilterProps = Pick<RadioGroupProps, 'onChange' | 'defaultValue'> & {
  items: RadioItem[];
};

export const RadioFilter = observer((props: RadioFilterProps) => {
  const classes = useStyles(props);
  const { items, ...groupProps } = props;
  return (
    <BorderedFilterFieldGroup label="Display Runs" className={clsx(classes.formControl, classes.toggleButton)}>
      <RadioGroup {...groupProps}>
        {items.map(({ label, value }) => {
          return (
            <FormControlLabel
              key={_.kebabCase(label)}
              control={<Radio value={value} name={_.kebabCase(label)} />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </BorderedFilterFieldGroup>
  );
});
