import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontFamily: 'Raleway',
    color: '#ffffff',
  },
  formControl: {
    padding: theme.spacing(2, 2),
    paddingTop: 0,

    minWidth: 180,
    fontFamily: 'Raleway',
    width: '100%',
    // paddingRight: '70px',
  },
  selectionStyle: {
    fontFamily: 'Raleway',
  },
  formBorder: {
    transform: 'translate(8px, -6px)',
    fontSize: 12,
    // backgroundColor: '#424242',
  },
  toggleGroup: {
    paddingBottom: theme.spacing(2),
  },
  toggleButton: {
    padding: theme.spacing(0, 2),
  },
  resetButton: {
    marginTop: '15px',
  },
  applyButton: {
    marginLeft: '1rem',
  },
}));
