import React from 'react';

import { observer } from 'mobx-react';

import { FormControl, FormGroup, FormGroupProps, FormLabel } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // padding: theme.spacing(0, 2),
  },
  label: {
    transform: 'translate(8px, -6px)',
    fontSize: 12,
    // backgroundColor: '#424242',

    backgroundColor: '#4C4C4C',
    padding: '0px 4px 0 4px',
    position: 'absolute',
  },
  formControl: {
    width: '100%',
  },
  formGroup: {
    border: '1px solid #757575',
    borderRadius: 4,
    padding: 10,
  },
}));

interface BorderedFilterFieldGroupProps extends Pick<FormGroupProps, 'children'> {
  label: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const BorderedFilterFieldGroup = observer((props: BorderedFilterFieldGroupProps): React.ReactElement | null => {
  const { className, label } = props;
  const classes = useStyles(props);
  return (
    <FormControl component="fieldset" className={clsx(classes.root, classes.formControl, className)}>
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <FormGroup className={classes.formGroup}>{props.children}</FormGroup>
    </FormControl>
  );
});
