import React, { useCallback, useEffect } from 'react';

import { observer } from 'mobx-react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';
import { ModalInfo } from 'services/ModalsService';

import { UseStyles } from 'styles/utilityTypes';
import { API, AppState, ARMFlowForms, ModalType, ProcessingServiceModalIds } from 'utils/constants';

import InfoViewer from './dialogs/InfoViewer';
import LogViewer from './dialogs/LogViewer';
import { ApplicationErrorModal } from './dialogs/modals/ApplicationErrorModal';
import { GeneralProcessErrorsModal } from './dialogs/modals/GeneralProcessErrorsModal';
import { GenericModal } from './dialogs/modals/GenericModal';
import { PipelineErrorHistoryModal } from './dialogs/modals/PipelineErrorHistoryModal';
import { ProcessStatesModal } from './dialogs/modals/ProcessStatesModal';
import { ProgressModal } from './dialogs/modals/ProgressModal';
import { ModalDialogProps } from './dialogs/modals/props';
import { PruneDataFormDialog } from './dialogs/pruneDataForm/PruneDataFormDialog';
import { ReleaseDataFormDialog } from './dialogs/releaseDataForm/ReleaseDataFormDialog';
import { RunBundlerFormDialog } from './dialogs/runBundlerForm/RunBundlerFormDialog';
import { RunProcessFormDialog } from './dialogs/runProcessForm/RunProcessFormDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export interface ModalsViewFuncProps extends UseStyles<typeof useStyles> {
  className?: string;
}

/** Renders modal components based on `ModalsService.modalStack` items */
const ModalsView = observer((props: ModalsViewFuncProps): React.ReactElement | null => {
  const { className } = props;

  const classes = useStyles(props);
  const { modalsService, processingService } = useServices();

  const { modalStack } = modalsService;
  const { status } = processingService;

  const checkProcessingServiceStatus = useCallback(() => {
    const { state, message } = status;
    switch (state) {
      case AppState.LOADING: {
        modalsService.pushModal(generateLoadingModalInfo(message));
        break;
      }
      case AppState.IDLE: {
        // Remove loading modal from stack since status is idle
        modalsService.popModal(ProcessingServiceModalIds.LOADING);
        break;
      }
      case AppState.ERROR: {
        modalsService.pushModal({
          id: ProcessingServiceModalIds.ERROR,
          title: 'Processing Service Error',
          message: message,
          type: ModalType.Error,
        });
        break;
      }
      default:
        break;
    }
  }, [modalsService, status]);

  const handleCloseButton = useCallback(
    (modal_id: string) => {
      // Revert processing service status to idle if associated ID is found
      if (Object.values(ProcessingServiceModalIds).includes(modal_id as ProcessingServiceModalIds)) {
        processingService.updateStatus(AppState.IDLE, null);
      }

      // Remove modal from service stack
      modalsService.popModal(modal_id);
    },
    [modalsService, processingService]
  );

  /** Check current status of ProcessingService, overriding currently rendered modals accordingly */
  useEffect(() => {
    checkProcessingServiceStatus();
  }, [checkProcessingServiceStatus]);

  const modalComponentStack = modalStack.map((info, idx) => (
    <Modal key={`modal-component-stack-${idx}`} info={info} onClose={handleCloseButton} />
  ));

  return <div className={clsx(classes.root, className)}>{modalComponentStack}</div>;
});

export default ModalsView;

type ModalProps = {
  key: string;
  info: ModalInfo;
  onClose: (modalId: string) => void;
};

const Modal = ({ info, onClose: handleCloseButton, ...props }: ModalProps) => {
  const { type: modalType, conditionalKey } = info;

  // TODO: combine `ModalDialogProps` and `ModalProps`; props are shared
  const modalProps: ModalDialogProps = { ...props, modalInfo: info, onModalClose: handleCloseButton };

  switch (modalType) {
    case ModalType.Loading:
      return <ProgressModal key={props.key} modalInfo={info} />;

    case ModalType.Error:
      return <ApplicationErrorModal {...modalProps} />;

    case ModalType.PipelineError:
      return <PipelineErrorHistoryModal {...modalProps} hideTitleINC />;

    case ModalType.SystemErrors:
      return <GeneralProcessErrorsModal {...modalProps} />;

    case ModalType.PipelineErrorHistory:
      return <PipelineErrorHistoryModal {...modalProps} showTabs showErrorHistory />;

    case ModalType.ProcessStates:
      return <ProcessStatesModal {...modalProps} />;

    case ModalType.Generic:
      return <GenericModal {...modalProps} />;

    case ModalType.InfoViewer:
      // TODO: attempting to open realtime-log after process completed doesn't show good feedback
      return <InfoViewer {...modalProps} />;

    case ModalType.LogViewer:
      return <LogViewer {...modalProps} />;

    case ModalType.Form: {
      const formKey = conditionalKey as ARMFlowForms;
      switch (formKey) {
        case ARMFlowForms.RUN_PROCESS:
          return <RunProcessFormDialog validatorPath={API.VALIDATE_RUN_PROCESS} {...modalProps} />;
        case ARMFlowForms.RELEASE_DATA:
          return <ReleaseDataFormDialog validatorPath={API.VALIDATE_RELEASE_DATA} {...modalProps} />;
        case ARMFlowForms.PRUNE_DATA:
          return <PruneDataFormDialog validatorPath={API.VALIDATE_PRUNE_DATA} {...modalProps} />;
        case ARMFlowForms.RUN_BUNDLER:
          return <RunBundlerFormDialog {...modalProps} />;
        default:
          return null;
      }
    }

    default:
      return null;
  }
};

const generateLoadingModalInfo = (message: string): ModalInfo => {
  return {
    id: ProcessingServiceModalIds.LOADING,
    title: message,
    message: message,
    type: ModalType.Loading,
  };
};
