import React from 'react';

import { observer } from 'mobx-react';

import { Autocomplete, FormControl, TextField } from '@mui/material';

import { useServices } from 'services';

import { processNameFilterOptions } from 'components/autocomplete/filterOptions';
import { ProcessNameListItem } from 'components/autocomplete/ProcessNameListItem';

import { useStyles } from '../definitions/styles';
import { FilterFieldProps, TextFieldAutocomplete } from '../definitions/types';

export const PipelineNameFilter = observer((props: FilterFieldProps<TextFieldAutocomplete['onChange']>) => {
  const { onChange } = props;
  const { processingService } = useServices();
  const classes = useStyles(props);
  const { currentModeFilters: filters, processesMap } = processingService;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Autocomplete
        multiple
        value={Array.from(filters.processName)}
        onChange={onChange}
        options={processingService.processNames}
        filterOptions={processNameFilterOptions(processesMap)}
        renderOption={(props, option) => (
          <ProcessNameListItem {...props} processName={option} processInfo={processesMap[option]} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="PCM Process Name"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </FormControl>
  );
});
