/** @jsxImportSource @emotion/react */
import React, { FormEvent, useMemo } from 'react';

import { observer } from 'mobx-react';

import { RadioGroupProps } from '@mui/material';

import clsx from 'clsx';
import _ from 'lodash';

import { useServices } from 'services';

import { ToggleRunType } from 'models/Filters';
import { UseStyles } from 'styles/utilityTypes';
import { ProcessType, RefreshReason } from 'utils/constants';

import { ApplyFilterButtons } from '../ApplyFilterButtons';
import { LocationFilter } from '../components/LocationFilter';
import { PipelineNameFilter } from '../components/PipelineNameFilter';
import { PipelineTypeFilter } from '../components/PipelineTypeFilter';
import { RadioFilter } from '../components/RadioItem';
import { RanByUserFilter } from '../components/RanByUserFilter';
import { ToggleFilter, ToggleFilterProps } from '../components/ToggleFilter';
import { useStyles } from '../definitions/styles';
import { RadioItem } from '../definitions/types';
import { TimelineDatePicker } from '../TimelineDatePicker';

export interface FilterTabProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const FilterTab = observer((props: FilterTabProps): React.ReactElement | null => {
  const { className } = props;

  const classes = useStyles(props);
  const { processingService } = useServices();
  const { currentModeFilters: filters } = processingService;

  const onApplyFilters = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    processingService.applyFilters(RefreshReason.Manual);
  };

  const onResetFilters = () => {
    filters.reset();
    processingService.applyFilters();
  };

  const onPipelineTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ProcessType;
    if (Object.values(ProcessType).includes(value)) {
      if (event.target.checked) {
        filters.addType(value as ProcessType);
      } else {
        filters.removeType(value as ProcessType);
      }
    }
  };

  const onPipelineNameChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    filters.updateProcessNames(value);
  };

  const onLocationChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    filters.updateLocations(value);
  };

  const onUserChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    filters.updateUsers(value);
  };

  const onShowRunTypeChange: RadioGroupProps['onChange'] = (event, value) => {
    const radioValue = value as ToggleRunType;
    switch (radioValue) {
      case ToggleRunType.Regular:
        filters.toggleShowRegularRuns(true);
        filters.toggleShowReprocessingRuns(false); // unset the others
        break;

      case ToggleRunType.Reprocessing:
        filters.toggleShowReprocessingRuns(true);
        filters.toggleShowRegularRuns(false); // unset the others
        break;

      case ToggleRunType.All:
      default:
        // set all to True
        filters.toggleShowRegularRuns(true);
        filters.toggleShowReprocessingRuns(true);
        break;
    }
  };

  // Construct list of toggle filters items
  const toggleItems: ToggleFilterProps[] = useMemo(() => {
    const items: ToggleFilterProps[] = [
      {
        label: 'Show pipeline errors only',
        activeKey: 'pipelineErrorsOnly',
        onChange: (e) => filters.toggleErrorsOnlyDisplay(e.target.checked),
      },
    ];
    return items;
  }, [filters]);

  // Construct list of toggle filters items
  const radioItems: RadioItem[] = useMemo(() => {
    const items: RadioItem[] = [
      {
        label: 'Show all',
        value: ToggleRunType.All,
      },
      {
        label: 'Show standard only',
        value: ToggleRunType.Regular,
      },
      {
        label: 'Show reprocessing only',
        value: ToggleRunType.Reprocessing,
      },
    ];
    return items;
  }, []);

  // Determine default radio selection
  const defaultRunType = useMemo(() => {
    if (filters.showRegularRuns && !filters.showReprocessingRuns) {
      return ToggleRunType.Regular;
    } else if (filters.showReprocessingRuns && !filters.showRegularRuns) {
      return ToggleRunType.Reprocessing;
    }
    return ToggleRunType.All;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run on first render

  return (
    <div className={clsx(classes.root, className)}>
      <form onSubmit={onApplyFilters}>
        <TimelineDatePicker className={classes.formControl} />
        <PipelineTypeFilter onChange={onPipelineTypeChange} />
        <PipelineNameFilter onChange={onPipelineNameChange} />
        <LocationFilter onChange={onLocationChange} />
        <RanByUserFilter onChange={onUserChange} />
        <div className={classes.toggleGroup}>
          <RadioFilter items={radioItems} onChange={onShowRunTypeChange} defaultValue={defaultRunType} />
        </div>
        <div className={classes.toggleGroup}>
          {toggleItems.map((item) => (
            <ToggleFilter key={_.kebabCase(item.activeKey)} {...item} />
          ))}
        </div>
        <ApplyFilterButtons applyButtonType="submit" className={classes.applyButton} onReset={onResetFilters} />
      </form>
    </div>
  );
});

export default FilterTab;
