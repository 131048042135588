import React from 'react';

import { observer } from 'mobx-react';

import { Autocomplete, FormControl, TextField } from '@mui/material';

import { useServices } from 'services';

import { useStyles } from '../definitions/styles';
import { FilterFieldProps, TextFieldAutocomplete } from '../definitions/types';

export const LocationFilter = observer((props: FilterFieldProps<TextFieldAutocomplete['onChange']>) => {
  const { onChange } = props;
  const { processingService } = useServices();
  const classes = useStyles(props);
  const filters = processingService.currentModeFilters;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Autocomplete
        multiple
        value={Array.from(filters.location)}
        onChange={onChange}
        options={processingService.locations}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Location"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </FormControl>
  );
});
