import { makeAutoObservable } from 'mobx';

import { AxiosResponse } from 'axios';

import { API } from 'utils/constants';
import { AccountDetails } from 'utils/types';

import AdiApiService from './AdiApiService';

type AuthServiceProps = {
  adiApiService: AdiApiService;
};

export default class AuthService {
  private adiApiService: AdiApiService;

  accountDetails: AccountDetails | null;

  constructor({ adiApiService }: AuthServiceProps) {
    this.adiApiService = adiApiService;
    this.accountDetails = null;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _setAccountDetails(accountDetails: AuthService['accountDetails']) {
    this.accountDetails = accountDetails;
  }

  fetchCurrentUser() {
    // Fetch the logged in user if it has not been set yet
    if (!this.accountDetails) {
      return this.adiApiService.axios
        .get(API.ME)
        .then((response) => {
          const data: AccountDetails = response.data;
          const { name, email } = data;
          if (name) {
            this._setAccountDetails({ name, email });
          }
        })
        .catch((error) => {
          this.adiApiService.handleError(error, {
            id: 'error-retrieving-current-user',
            message: 'Cannot retrieve current user.',
          });
          return ['Cannot retrieve current user.', []];
        });
    }
  }

  logout() {
    return this.adiApiService.axios
      .post(API.LOGOUT)
      .then((_: AxiosResponse) => {
        this.accountDetails = null;
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: 'error-in-logout',
          title: 'Error During Logout',
        });

        return ['Cannot logout.', []];
      });
  }
}
