import { useEffect } from 'react';

import { Stack } from '@mui/material';

import { useServices } from 'services';

import { ActionBar } from './actionBar/ActionBar';
import Drawer from './Drawer';
import FiltersView from './FiltersView';
import Timelines from './timeline/Timelines';

const Dashboard = () => {
  const { processingService } = useServices();

  useEffect(() => {
    // NOTE: Initial load of the timeline data may appear as though it's returned nothing
    //       before suddenly populating. This is likely due to React's StrictMode's
    //       double-render, which is causing the app to query the initial timeline twice.
    processingService.initializeDashboard();
  }, [processingService]);

  return (
    <>
      <ActionBar />
      <Stack direction={'row'}>
        <Timelines />
        <Drawer>
          <FiltersView />
        </Drawer>
      </Stack>
    </>
  );
};

export default Dashboard;
