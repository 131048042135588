import React from 'react';

import { observer } from 'mobx-react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Divider, Link, ListItemText, Menu, MenuItem, PopoverOrigin } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { DateTime } from 'luxon';

import { useServices } from 'services';

import { PRUNE_DATA_EMPTY_FORM, PruneDataFormFields } from 'models/PruneDataForm';
import { RELEASE_DATA_EMPTY_FORM, ReleaseDataFormFields } from 'models/ReleaseDataForm';
import { RUN_BUNDLER_EMPTY_FORM, RunBundlerFormFields } from 'models/RunBundlerForm';
import { RUN_PROCESS_EMPTY_FORM, RunProcessFormFields } from 'models/RunProcessForm';
import { ArmflowFormFieldsMap } from 'models/types';
import { UseStyles } from 'styles/utilityTypes';
import {
  ActionMenuItems,
  AnchorPoints,
  ARMFlowForms,
  ErrorHistoryType,
  FileDownloadTypes,
  ProcessType,
} from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';
import { generateRegularProcessingId } from 'utils/utils';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface ActionMenuProps extends UseStyles<typeof useStyles> {
  anchorEl: HTMLElement | null;
  pipelineId?: PipelineIdentifier;
  processType?: ProcessType;
  errorHistoryType?: ErrorHistoryType;
  hideDivider?: boolean;

  disabledActions?: Partial<Record<ActionMenuItems, boolean>>;
  hideActions?: ActionMenuProps['disabledActions'];
  runProcessAutofillData?: Partial<RunProcessFormFields>;
  releaseDataAutofillData?: Partial<ReleaseDataFormFields>;
  pruneDataAutofillData?: Partial<PruneDataFormFields>;
  runBundlerAutofillData?: Partial<RunBundlerFormFields>;
  logDate?: DateTime;
  onClose?: () => void;

  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;

  className?: string;
}

const ActionMenu = observer((props: ActionMenuProps): React.ReactElement | null => {
  const { className } = props;

  const classes = useStyles(props);
  const { actionBarService, processingService } = useServices();

  const {
    anchorEl,
    runProcessAutofillData,
    releaseDataAutofillData,
    pruneDataAutofillData,
    runBundlerAutofillData,
    disabledActions,
    hideActions,
    pipelineId,
    processType,
    errorHistoryType,
    hideDivider,
    logDate,
    anchorOrigin,
    transformOrigin,
    onClose,
  } = { ...AnchorPoints.BottomLeft, ...props };

  const { isDialogOpen } = actionBarService;
  const { processesMap } = processingService;

  const processName = pipelineId ? pipelineId.process_name : '';
  const site = pipelineId ? pipelineId.site : '';
  const facility = pipelineId ? pipelineId.facility : '';
  const dataDate = logDate ? logDate : null;
  const errorHistoryOptionText =
    errorHistoryType === ErrorHistoryType.System ? `${errorHistoryType} Errors` : `${errorHistoryType} Error History`;
  const processTypeText =
    processType && [ProcessType.INGEST, ProcessType.VAP].includes(processType) ? processType : 'Ingest/VAP';

  const inCell = Boolean(
    runProcessAutofillData || releaseDataAutofillData || pruneDataAutofillData || runBundlerAutofillData
  );
  const isIngest = processType === ProcessType.INGEST;
  const isHybrid = !!processesMap[processName]?.is_hybrid;
  const showViewProcessLog = !!inCell || !!errorHistoryType;
  const showProcessStates = !!pipelineId;
  const showDivider: boolean = !hideDivider && (!!hideActions || inCell);

  const handleClose = () => {
    actionBarService.closeAllDialogs();
    if (onClose) onClose();
  };

  const openFormDialogAutofill = <T extends ARMFlowForms>(formType: T, autofillData: ArmflowFormFieldsMap[T]) => {
    processingService.autofillForm(formType, autofillData);
    actionBarService.setFormDialogOpen(formType, true);

    /**
     * What do these do?
     * - ProcessRun: closes context menu
     * - ActionBar: closes (this) menu (it used to reset forms but those are moving to form components themselves/it's unnecessary)
     */
    if (onClose) onClose();
  };

  const openInfoViewer = () => {
    if (dataDate) {
      processingService.setInfoViewerParams({
        processName,
        dataDate,
        site,
        facility,
        title:
          pipelineId && logDate
            ? `${pipelineId.process_name} - ${pipelineId.site} ${pipelineId.facility} - ${logDate.toISODate()}`
            : null,
      });
      actionBarService.setInfoViewerOpen(true);
    }

    // Close ActionMenu after opening infoViewer (remove line if we'd like to leave menu open after exiting InfoViewer)
    if (onClose) onClose();
  };

  const openLogViewer = (fileType: FileDownloadTypes) => {
    if (!pipelineId || !dataDate) {
      console.warn("Cannot retrieve log file: Missing 'pipelineId' or 'date'");
      return;
    }

    processingService.fetchLogFile(pipelineId, { date: dataDate.toISODate() }, fileType);
    if (onClose) onClose();
  };

  const openErrorHistory = () => {
    switch (errorHistoryType) {
      case ErrorHistoryType.Pipeline:
        if (pipelineId) {
          processingService.fetchPipelineError({
            // TODO: (2024-02-28, Elvis) Eventually will need to update processingId generation so it doesn't always default to regular
            pipelineId: { ...pipelineId, processType, processingId: generateRegularProcessingId(pipelineId) },
            defaultTab: processType,
            getHistory: true,
          });
        }
        break;
      case ErrorHistoryType.System:
        processingService.fetchSystemErrors();
        break;
      default:
        break;
    }

    if (onClose) onClose();
  };

  const openAllPipelineErrors = () => {
    processingService.fetchAllOpenErrors();
  };

  const openProcessStates = () => {
    if (pipelineId) {
      processingService.openPipelineStatesModal(pipelineId);
    }
    if (onClose) onClose();
  };

  const handleRefresh = () => {
    processingService.refresh();
    handleClose();
  };

  return (
    <div className={clsx(classes.root, className)}>
      {/* Action button (changes based on processing mode) */}
      {/* Top half of menu includes: Info(Cell only), Ingest Log, Collections log (if ingest), Pipeline Process States, and Pipeline Error History */}
      {/* Bottom Half Includes: Run Bundler (ingest only), Run Ingest, Release Data, Prune Data */}
      <Menu
        id="action-menu"
        open={Boolean(anchorEl) && !isDialogOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        // transitionDuration={100}
        MenuListProps={{ dense: !inCell ? true : false }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {/* Only show these options when menu is rendered within cell */}
        {!hideActions?.[ActionMenuItems.INFO] && inCell && (
          <MenuItem disabled={disabledActions?.[ActionMenuItems.INFO]} onClick={() => openInfoViewer()}>
            <ListItemText>Info</ListItemText>
          </MenuItem>
        )}

        {/* View Logs items */}
        {/* TODO: should this option render if processing interval in blank/nonexistent? */}
        {!hideActions?.[ActionMenuItems.VIEW_PROCESS_LOG] && showViewProcessLog && (
          <MenuItem
            disabled={disabledActions?.[ActionMenuItems.VIEW_PROCESS_LOG]}
            onClick={() => openLogViewer(FileDownloadTypes.PROCESS_LOG)}
          >
            <ListItemText>View {processTypeText} log</ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.VIEW_COLLECTIONS_LOG] && isIngest && !isHybrid && (
          <MenuItem
            disabled={disabledActions?.[ActionMenuItems.VIEW_COLLECTIONS_LOG]}
            onClick={() => openLogViewer(FileDownloadTypes.COMLOG)}
          >
            <ListItemText>View Collections log</ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.VIEW_BUNDLE_LOG] && isIngest && !isHybrid && (
          <MenuItem
            disabled={disabledActions?.[ActionMenuItems.VIEW_BUNDLE_LOG]}
            onClick={() => openLogViewer(FileDownloadTypes.BUNDLE_LOG)}
          >
            <ListItemText>View Bundle log</ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.PIPELINE_PROCESS_STATES] && showProcessStates && (
          <MenuItem onClick={() => openProcessStates()}>
            <ListItemText>Pipeline Process States</ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.ERROR_HISTORY] && errorHistoryType && (
          <MenuItem disabled={disabledActions?.[ActionMenuItems.ERROR_HISTORY]} onClick={openErrorHistory}>
            <ListItemText>
              <span style={{ textTransform: 'capitalize' }}>{errorHistoryOptionText}</span>
            </ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.ERROR_HISTORY] && errorHistoryType && !inCell && (
          <MenuItem disabled={disabledActions?.[ActionMenuItems.ERROR_HISTORY]} onClick={openAllPipelineErrors}>
            <ListItemText>
              <span style={{ textTransform: 'capitalize' }}>View all open errors</span>
            </ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.VIEW_ARMFLOW_PROCESS_CONFIG] && inCell && (
          <MenuItem
            disabled={disabledActions?.[ActionMenuItems.VIEW_ARMFLOW_PROCESS_CONFIG]}
            onClick={() => openLogViewer(FileDownloadTypes.AIRFLOW_PROCESS_CONFIG)}
          >
            <ListItemText>View ARMFlow Process Config</ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.VIEW_AIRFLOW_DAG] && inCell && (
          <MenuItem
            component={Link}
            disabled={disabledActions?.[ActionMenuItems.VIEW_AIRFLOW_DAG]}
            href={`/dags/run_process_${processName}_${site}_${facility}_regular/grid`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemText>View Airflow DAG</ListItemText>
            <OpenInNewIcon />
          </MenuItem>
        )}

        {showDivider && <Divider />}

        {!hideActions?.[ActionMenuItems.RUN_BUNDLER] && isIngest && (
          <MenuItem
            disabled={disabledActions?.[ActionMenuItems.RUN_BUNDLER]}
            onClick={() =>
              openFormDialogAutofill(ARMFlowForms.RUN_BUNDLER, { ...RUN_BUNDLER_EMPTY_FORM, ...runBundlerAutofillData })
            }
          >
            <ListItemText>Run Bundler</ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.RUN_PROCESS] && (
          <MenuItem
            disabled={disabledActions?.[ActionMenuItems.RUN_PROCESS]}
            onClick={() =>
              openFormDialogAutofill(ARMFlowForms.RUN_PROCESS, { ...RUN_PROCESS_EMPTY_FORM, ...runProcessAutofillData })
            }
          >
            <ListItemText>Run {processTypeText}</ListItemText>
          </MenuItem>
        )}

        {/* Only render refresh option when not in timelines cell */}
        {!hideActions?.[ActionMenuItems.REFRESH] && !inCell && (
          <MenuItem disabled={disabledActions?.[ActionMenuItems.REFRESH]} onClick={() => handleRefresh()}>
            <ListItemText>Refresh</ListItemText>
          </MenuItem>
        )}

        {!hideActions?.[ActionMenuItems.PRUNE_DATA] && (
          <MenuItem
            onClick={() =>
              openFormDialogAutofill(ARMFlowForms.PRUNE_DATA, { ...PRUNE_DATA_EMPTY_FORM, ...pruneDataAutofillData })
            }
          >
            <ListItemText>Prune data</ListItemText>
          </MenuItem>
        )}
        {!hideActions?.[ActionMenuItems.RELEASE_DATA] && (
          <MenuItem
            onClick={() =>
              openFormDialogAutofill(ARMFlowForms.RELEASE_DATA, {
                ...RELEASE_DATA_EMPTY_FORM,
                ...releaseDataAutofillData,
              })
            }
          >
            <ListItemText>Release data</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
});

export default ActionMenu;
