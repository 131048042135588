/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef } from 'react';

import { css, styled } from '@mui/material';

import { Text } from 'components/styles';
import { ProcessInfo } from 'utils/types';

interface ProcessNameListItemProps extends ComponentPropsWithoutRef<'li'> {
  processName: string;
  processInfo: ProcessInfo;
}
export const ProcessNameListItem = ({ processName, processInfo, ...props }: ProcessNameListItemProps) => {
  const { type } = processInfo;

  return (
    <li key={props.key} {...props}>
      <Text css={listItemStyle}>
        <span>{processName}</span>
        <TypeLabel>{type}</TypeLabel>
      </Text>
    </li>
  );
};
const listItemStyle = css`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
`;
const TypeLabel = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
  `
);
