/** @jsxImportSource @emotion/react */
import { ComponentPropsWithoutRef } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { css, IconButton, Popover, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { AnchorPointPresets, AnchorPoints, PROCESS_STATE_LEGEND_ENTRIES, RunMode } from 'utils/constants';

import { DescriptionEntry } from './DescriptionEntry';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export interface ProcessStateDescriptionsProps extends ComponentPropsWithoutRef<'div'> {
  position?: AnchorPointPresets;
  exclusions?: RunMode[];
}

export const ProcessStateDescriptionButton = ({
  className,
  position = AnchorPointPresets.LEFT_TOP,
  exclusions = [],
  ...props
}: ProcessStateDescriptionsProps) => {
  // // const [sampleState, setSampleState] = useState(null);

  const classes = useStyles(props);
  const popupState = usePopupState({ popupId: 'runModeDescriptions', variant: 'popover' });

  return (
    <div className={clsx(classes.root, className)} {...props}>
      <IconButton size="small" {...bindTrigger(popupState)}>
        <InfoOutlinedIcon />
      </IconButton>

      <Popover {...bindPopover(popupState)} {...AnchorPoints[position]}>
        <Stack css={popoverContainerStyle} gap={'0.5rem'}>
          {PROCESS_STATE_LEGEND_ENTRIES.map((entry, idx) =>
            exclusions.includes(entry.state) ? null : <DescriptionEntry key={idx} entry={entry} />
          )}
        </Stack>
      </Popover>
    </div>
  );
};

const popoverContainerStyle = css`
  width: 500px;
  padding: 1rem;
`;
