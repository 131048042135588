import React from 'react';

import { observer } from 'mobx-react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { Route, Routes } from 'react-router';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import { FeatureFlag } from 'utils/constants';
import { AppRoutes } from 'utils/routes';

import Dashboard from './Dashboard';
import ModalsView from './ModalsView';
import Navbar from './Navbar';
import Reprocessing from './Reprocessing';
import { LoadingPage } from './routing/LoadingPage';
import { PageNotFound } from './routing/PageNotFound';
import { FeatureWrapper } from './utility/featureFlags/FeatureWrapper';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      minWidth: 750,

      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'hsl(0, 0%, 10%)',
    },

    // We have to override global MultiGrid style to fix a bug in the timelines view where they have "overflow: auto hidden"
    // On macs or when you zoom way out in the browser, the browser is rendering this double value as overflow instead of hidden
    '@global': {
      '.ReactVirtualized__Grid': {
        overflow: 'hidden',
      },
      'div.TopRightGrid_ScrollWrapper > div': {
        overflow: 'hidden !important',
      },
      'div.BottomLeftGrid_ScrollWrapper > div': {
        overflow: 'hidden !important',
      },
    },
  });

export interface IAppProps extends WithServicesProps {
  classes: {
    root: string;
  };
}

class App extends React.Component<IAppProps> {
  constructor(props: Readonly<IAppProps>) {
    super(props);

    const { services } = this.props;
    const { featureFlagService } = services;

    if (!featureFlagService.features) {
      featureFlagService.refreshFeaturesFlags();
    }
  }

  render() {
    const { classes, services } = this.props;
    const { featureFlagService } = services;
    const { isFetching, isUninitialized } = featureFlagService;
    const featureFlagsLoading = isUninitialized || isFetching;

    return (
      <div className={classes.root}>
        {/* Navbar */}
        <Navbar />

        {/* Routes */}
        <Routes>
          <Route path={AppRoutes.Dashboard} element={<Dashboard />} />
          <Route
            path={AppRoutes.Reprocessing.Root}
            element={
              <FeatureWrapper
                featureId={FeatureFlag.REPROCESSING}
                fallbackComponent={featureFlagsLoading ? <LoadingPage /> : <PageNotFound />}
              >
                <Reprocessing />
              </FeatureWrapper>
            }
          />

          {/* Fallback route if no other matches are found */}
          <Route path={'*'} element={<PageNotFound />} />
        </Routes>

        {/* Miscellaneous/feedback components */}
        <ModalsView />
      </div>
    );
  }
}

export default withStyles(styles)(withServices(observer(App)));
