import { StrictMode } from 'react';

import 'mobx-react-lite/batchingForReactDom';

import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';

import 'ace-builds/src-noconflict/ace';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import ArmflowTheme from 'theme';

import {
  ActionBarService,
  AdiApiService,
  AuthService,
  DrawerService,
  FeatureFlagService,
  ModalsService,
  ProcessingService,
  ReprocessingService,
  ServicesProvider,
  StorageService,
} from 'services';
import Services from 'services/Services';

import App from 'components/App';

import * as serviceWorker from './serviceWorker';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    running: Palette['primary'];
    noinput: Palette['primary'];
    runBorder: Palette['primary'];
    transparent: Palette['primary'];
    pipelineInfo: Palette['primary'];
    qualityControlPassed: Palette['primary'];
    qualityControlFailed: Palette['primary'];
    quicklooksProduced: Palette['primary'];
    quicklooksMissing: Palette['primary'];
    filesMissing: Palette['primary'];
  }
  interface PaletteOptions {
    running: PaletteOptions['primary'];
    noinput: PaletteOptions['primary'];
    runBorder: PaletteOptions['primary'];
    transparent: PaletteOptions['primary'];
    pipelineInfo: PaletteOptions['primary'];
    qualityControlPassed: PaletteOptions['primary'];
    qualityControlFailed: PaletteOptions['primary'];
    quicklooksProduced: PaletteOptions['primary'];
    quicklooksMissing: PaletteOptions['primary'];
    filesMissing: PaletteOptions['primary'];
  }
}
declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    fontWeightSemiBold: number;
  }
}

const drawerService = new DrawerService();
const modalsService = new ModalsService();
const storageService = new StorageService();
const actionBarService = new ActionBarService({ storageService, drawerService, modalsService });
const adiApiService = new AdiApiService({ modalsService });
const authService = new AuthService({ adiApiService });
const reprocessingService = new ReprocessingService({ adiApiService });
const featureFlagService = new FeatureFlagService({ adiApiService });
const processingService = new ProcessingService({
  actionBarService,
  adiApiService,
  authService,
  modalsService,
  storageService,
});

const services: Services = {
  adiApiService,
  authService,
  drawerService,
  featureFlagService,
  modalsService,
  processingService,
  actionBarService,
  storageService,
  reprocessingService,
};

window.onresize = () => {
  actionBarService.refreshLabelWidth(); // TODO: if performance ever becomes an issue w/ this, consider throttling
};

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

function getPublicUrl() {
  return process.env.PUBLIC_URL;
}

root.render(
  <StrictMode>
    <BrowserRouter basename={getPublicUrl()}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ArmflowTheme}>
          <CssBaseline />
          <ServicesProvider services={services}>
            <App />
          </ServicesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
