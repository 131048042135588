/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { css, useTheme } from '@mui/material';

import { Text } from 'components/styles';

interface NonIdealStateProps {
  title: string;
  description: string;
  icon?: ReactNode;
  action?: ReactNode;
}

/**
 * @note Inspired by the [`NonIdealState`](https://blueprintjs.com/docs/#core/components/non-ideal-state) component in BlueprintJS
 */
export const NonIdealState = ({ icon, title, description, action }: NonIdealStateProps) => {
  const theme = useTheme();

  return (
    <div css={nonIdealStyle}>
      {icon && (
        <Text variant="h5" fontSize={48}>
          {icon}
        </Text>
      )}
      {title && (
        <Text variant="h5" css={{ fontWeight: theme.typography.fontWeightMedium, marginBottom: '.5rem' }}>
          {title}
        </Text>
      )}
      {description && <Text variant="subtitle1">{description}</Text>}
      {action && <span css={actionStyle}>{action}</span>}
    </div>
  );
};

const nonIdealStyle = css`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

const actionStyle = css`
  margin-top: 1.25rem;
`;
