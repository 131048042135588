import { action, makeObservable, observable } from 'mobx';

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults } from 'axios';

import { API, CsrfErrors, HttpErrorStatusCode, ModalType } from 'utils/constants';
import { CSRFRefreshResponse, Error400Response, Error404Response } from 'utils/types';
import { getDocumentHeadMetaValue } from 'utils/utils';

import ModalsService from './ModalsService';

const CSRFHeaderName = 'X-CSRFToken';
const MethodsRequiringCSRF: Array<keyof Omit<HeadersDefaults, 'options' | 'purge' | 'link' | 'unlink'>> = [
  'post',
  'put',
  'patch',
  'delete',
];

type AdiApiServiceProps = {
  modalsService: ModalsService;
};

type ErrorModalConfig = {
  id: string;
  title?: string;
  message?: string;
};

export default class AdiApiService {
  private modalsService: ModalsService;
  private currentCsrfToken: string | null = null;

  axios: AxiosInstance;

  validation: Partial<Record<API, Error400Response | null>> = {
    // Values of these will update on-submit
    [API.VALIDATE_RUN_PROCESS]: null,
    [API.VALIDATE_RELEASE_DATA]: null,
    [API.VALIDATE_PRUNE_DATA]: null,
  };

  constructor({ modalsService }: AdiApiServiceProps) {
    this.modalsService = modalsService;
    this.axios = axios.create({
      // baseURL: '/api/armflow/',
      // withCredentials: true,
      xsrfHeaderName: CSRFHeaderName,
    });

    // Set CSRF token as a common header (initially from <meta/> tag if exists)
    this._setCsrfFromMeta();

    // Clean validation object for path before sending requeset
    this.axios.interceptors.request.use((config) => {
      const { url } = config;
      const validationPath = url && url in this.validation ? (url as API) : null;
      if (validationPath) {
        this.clearValidatorError(validationPath);
      }
      return config;
    });

    // Interceptor handler in case CSRF needs to be refreshed
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const axiosError = this._toAxiosError(error);
        if (!axiosError) return Promise.reject(error);
        if (axiosError.response?.status !== HttpErrorStatusCode.CSRF_ERROR) return Promise.reject(error);
        if (this.get400ErrorMessage(axiosError) !== CsrfErrors.EXPIRED) return Promise.reject(error);

        // If error message matches expired, set new headers
        return this.refreshCsrfToken()
          .then((token) => {
            const newConfig: AxiosRequestConfig = { ...axiosError.config };
            if (newConfig.headers) {
              newConfig.headers[CSRFHeaderName] = token;
            }
            return newConfig;
          })
          .then((config: AxiosRequestConfig) => {
            return this.axios.request(config); // Resubmit the original request replaced with new CSRF token in header
          })
          .catch((refreshError: unknown) => {
            this.handleError(refreshError, {
              id: 'failed-csrf-refresh',
              title: 'Failed CSRF refresh',
            });
            return Promise.reject(refreshError);
          });
      }
    );

    makeObservable(this, {
      validation: observable,
      handleError: action,
      updateValidator: action,
      clearValidatorError: action,
    });
  }

  private get csrfTokenMeta(): string | null {
    return getDocumentHeadMetaValue('csrf_token');
  }

  private _setCsrfFromMeta = () => {
    const metaToken = this.csrfTokenMeta;
    if (metaToken) {
      if (!this.currentCsrfToken) {
        MethodsRequiringCSRF.forEach((method) => {
          this.axios.defaults.headers[method][CSRFHeaderName] = metaToken;
        });
        this.currentCsrfToken = metaToken;
      }
    }
  };

  private refreshCsrfToken = () => {
    const refreshPromise = this.axios.get(API.CSRF_REFRESH).then((response: AxiosResponse) => {
      const data: CSRFRefreshResponse = response.data;
      const { token } = data;

      MethodsRequiringCSRF.forEach((method) => {
        this.axios.defaults.headers[method][CSRFHeaderName] = token;
      });
      this.currentCsrfToken = token;

      return token;
    });

    return refreshPromise;
  };

  private _toAxiosError = (error: unknown): AxiosError | null => {
    if (axios.isAxiosError(error)) {
      return error as AxiosError;
    }
    return null;
  };

  get400ErrorMessage = (error: unknown, defaultMessage?: string): string => {
    const axiosError = this._toAxiosError(error);
    const unhandledDefaultMessage = `Unhandled error has occurred (${axiosError?.response?.statusText ?? 'UNKNOWN'})`;
    const codesWithErrors = [HttpErrorStatusCode.BAD_REQUEST, HttpErrorStatusCode.CSRF_ERROR];
    if (axiosError) {
      if (axiosError.response && codesWithErrors.includes(axiosError.response.status)) {
        const data = axiosError.response.data as Error400Response;
        const fallbackMessage = defaultMessage ?? unhandledDefaultMessage;
        try {
          const message = data.errors.at(0);
          return message?.message ?? fallbackMessage;
        } catch {
          return fallbackMessage;
        }
      }

      return unhandledDefaultMessage;
    }

    // Return raw error otherwise
    return `${error}`;
  };

  // TODO 2022-12-09: abstract method below and get400ErrorMessage() to a method with
  //                  parsing function as parameter
  get404ErrorMessage = (error: unknown, defaultMessage?: string): string => {
    const axiosError = this._toAxiosError(error);
    const unhandledDefaultMessage = `Unhandled error has occurred (${axiosError?.response?.statusText ?? 'UNKNOWN'})`;
    const codesWithErrors = [HttpErrorStatusCode.NOT_FOUND];

    if (axiosError) {
      if (axiosError.response && codesWithErrors.includes(axiosError.response.status)) {
        const data = axiosError.response.data as Error404Response;
        const fallbackMessage = defaultMessage ?? unhandledDefaultMessage;
        try {
          return data.message;
        } catch {
          return fallbackMessage;
        }
      }
      return unhandledDefaultMessage;
    }

    // Return raw error otherwise
    return `${error}`;
  };

  handleError = (error: unknown, errorConfig: ErrorModalConfig) => {
    const { id, title, message } = errorConfig;
    const axiosError = this._toAxiosError(error);
    const httpStatus: HttpErrorStatusCode | null =
      axiosError && axiosError.response && Object.values(HttpErrorStatusCode).includes(axiosError.response.status)
        ? (axiosError.response.status as HttpErrorStatusCode)
        : null;

    if (axiosError && httpStatus) {
      /**
       * Handle Axios errors based on HTTP status
       */

      const urlPath = axiosError.config.url;
      const responseData = axiosError.response?.data;

      switch (axiosError.response?.status) {
        case HttpErrorStatusCode.BAD_REQUEST:
          // Only push modal if our validation object returned (set in transformRequest) doesn't fit the type Error400Response
          //   - Ex. if(this.validation[axiosError.])
          // If path is a key of this.validation, cast this object as a Error400Response
          if (urlPath && urlPath in this.validation) {
            this.updateValidator(urlPath as API, responseData as Error400Response);
          } else {
            this.modalsService.pushModal({
              id: id ?? 'bad-request',
              title: title ?? 'Bad Request',
              message: this.get400ErrorMessage(error, message),
              type: ModalType.Error,
            });
          }
          break;
        case HttpErrorStatusCode.UNAUTHORIZED:
          this.modalsService.pushModal({
            id: 'not-logged-in',
            title: 'No User Logged In',
            message: 'Dashboard is view-only if not logged in. Please log in to modify processes.',
            type: ModalType.Error,
          });
          break;

        case HttpErrorStatusCode.FORBIDDEN:
          this.modalsService.pushModal({
            id: 'not-authorized',
            title: 'User Not Permitted',
            message: 'You do not have permission to conduct this action',
            type: ModalType.Error,
          });
          break;

        case HttpErrorStatusCode.NOT_FOUND:
          this.modalsService.pushModal({
            id: id ?? 'not-found',
            title: title ?? 'Resource Not Found',
            message: this.get404ErrorMessage(error, message),
            type: ModalType.Error,
          });
          break;

        case HttpErrorStatusCode.INTERNAL_SERVER_ERROR:
          this.modalsService.pushModal({
            id: 'server-error',
            title: 'Unexpected Server Error',
            message: `${message ?? 'Unexpected error'} - Please contact an admin for assistance.`,
            type: ModalType.Error,
          });
          break;

        case HttpErrorStatusCode.CSRF_ERROR:
          this.modalsService.pushModal({
            id: 'csrf-error',
            title: 'CSRF Error',
            message: this.get400ErrorMessage(axiosError),
            type: ModalType.Error,
          });
          break;

        default:
          // TODO: write a default case here
          break;
      }
    } else {
      /**
       * Handle every other type of error here
       */
      this.modalsService.pushModal({
        id: id ?? 'unknown-client-error',
        title: title ?? 'Unexpected Client Error',
        message: message ?? 'Unexpected error - Please contact an admin for assistance.',
        type: ModalType.Error,
      });
      console.error(error);
    }
  };

  updateValidator = (path: API, value: Error400Response | null) => {
    this.validation[path] = value;
  };

  clearValidatorError = (path: API) => {
    this.validation[path] = null;
  };
}
