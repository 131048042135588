import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { Checkbox, FormControlLabel, Stack, TextField, TextFieldProps } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import clsx from 'clsx';
import _ from 'lodash';
import { DateTime } from 'luxon';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { CommonDatePickerProps, CommonTextFieldProps, PastDaysFieldMax, PastDaysFieldMin } from 'utils/constants';
import { DateRange } from 'utils/types';
import { getLastNumDaysDates } from 'utils/utils';

import { BorderedFilterFieldGroup } from './components/BorderedFilterFieldGroup';
import { PastDaysField } from './PastDaysField';

const useStyles = makeStyles((theme) => ({
  root: {},
  borderedGroup: {
    paddingRight: theme.spacing(1),
  },
  datePickerTextInput: {},
}));

export interface TimelineDatePickerProps extends UseStyles<typeof useStyles> {
  className?: string;
}

export const TimelineDatePicker = observer((props: TimelineDatePickerProps): React.ReactElement | null => {
  const { className } = props;
  const classes = useStyles(props);

  const theme = useTheme();
  const { processingService } = useServices();
  const { currentModeFilters: filters } = processingService;
  const { lastNumDays, timelineStartDate, timelineEndDate, useLastNumDaysField } = filters;
  const initialPastDaysValue = lastNumDays?.toString() ?? null;

  const [startDate, setStartDate] = useState<DateTime | null>(timelineStartDate);
  const [endDate, setEndDate] = useState<DateTime | null>(timelineEndDate);
  const [pastDaysInputString, setPastDaysInputString] = useState<string | null>(initialPastDaysValue);

  useEffect(() => {
    if (filters.changeCounter === 0) {
      // Sync start/end dates to ProcessingService (should be default when filters.changeCounter is 0)
      setStartDate(timelineStartDate);
      setEndDate(timelineEndDate);
      setPastDaysInputString(initialPastDaysValue);
    }
  }, [filters.changeCounter, initialPastDaysValue, timelineEndDate, timelineStartDate]);

  const handleTimelineStartDateChange = (value: DateTime | null) => {
    setStartDate(value);
    filters.setTimelineStartDate(value);
  };
  const handleTimelineEndDateChange = (value: DateTime | null) => {
    setEndDate(value);
    filters.setTimelineEndDate(value);
  };

  const updateDateFieldsAndFilter = (dateRange: DateRange) => {
    const { begin, end } = dateRange;

    // Update filter dates
    setStartDate(begin);
    setEndDate(end);
    filters.setTimelineDateRange(begin, end);
  };

  const handleNumDaysInputUpdate = (numDays: number) => {
    const validNumber = !_.isNaN(numDays);

    // Auto-set to boundaries if numDays falls outside of them
    if (numDays < PastDaysFieldMin) numDays = PastDaysFieldMin;
    else if (numDays > PastDaysFieldMax) numDays = PastDaysFieldMax;

    // Construct DateTime objects counting back from today
    const pastDaysRange = getLastNumDaysDates(numDays);

    // Update text input and execute handler range
    setPastDaysInputString(!validNumber ? null : numDays.toString());
    filters.setLastNumDays(!validNumber ? null : numDays);
    updateDateFieldsAndFilter(pastDaysRange);
  };

  // const handleClearDates = () => {
  //   updateDateFieldsAndFilter({ begin: null, end: null });
  // };

  return (
    <BorderedFilterFieldGroup label="Date Range" className={clsx(className)}>
      <Stack className={classes.borderedGroup} gap={theme.spacing(1)}>
        <FormControlLabel
          control={
            <Checkbox
              checked={useLastNumDaysField}
              onChange={(_, checked) => {
                // Auto-set the dates when the last-days toggle is enabled
                if (pastDaysInputString) handleNumDaysInputUpdate(parseInt(pastDaysInputString));
                filters.toggleUseLastNumDaysField(checked);
              }}
            />
          }
          label={
            <PastDaysField
              inputValue={pastDaysInputString}
              disableNumberInput={!useLastNumDaysField}
              onInputChange={(value) => setPastDaysInputString(value)}
              onNumDaysChange={handleNumDaysInputUpdate}
            />
          }
        />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Stack direction="row" gap={theme.spacing(2)}>
            <DatePicker
              {...CommonDatePickerProps}
              label="Start"
              value={startDate}
              disabled={useLastNumDaysField}
              defaultCalendarMonth={endDate ?? undefined}
              onChange={handleTimelineStartDateChange}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...CommonTextFieldProps(params)}
                  size="small"
                  variant="standard"
                  inputProps={{ ...params.inputProps, className: classes.datePickerTextInput, placeholder: undefined }}
                  error={!processingService.isValidDateRange([startDate, endDate], false)}
                />
              )}
            />
            <DatePicker
              {...CommonDatePickerProps}
              label="End"
              value={endDate}
              disabled={useLastNumDaysField}
              defaultCalendarMonth={startDate ?? undefined}
              onChange={handleTimelineEndDateChange}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...CommonTextFieldProps(params)}
                  size="small"
                  variant="standard"
                  inputProps={{ ...params.inputProps, className: classes.datePickerTextInput, placeholder: undefined }}
                  // inputProps={{ ...params.inputProps, placeholder: undefined, style: { padding: theme.spacing(0) } }}
                  error={!processingService.isValidDateRange([startDate, endDate], false)}
                />
              )}
            />

            {/* TODO: figure out styling so date field text isn't clipped */}
            {/* <Tooltip title="Clear Dates">
              <IconButton onClick={handleClearDates}>
                <ClearIcon />
              </IconButton>
            </Tooltip> */}
          </Stack>
        </LocalizationProvider>
      </Stack>
    </BorderedFilterFieldGroup>
  );
});
