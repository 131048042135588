import { makeAutoObservable } from 'mobx';

import { AxiosResponse } from 'axios';

import { API, FeatureFlag } from 'utils/constants';

import AdiApiService from './AdiApiService';

const EMPTY_FLAGS: FeatureFlagService['features'] = {};

type FeatureFlagServiceProps = {
  adiApiService: AdiApiService;
};

export default class FeatureFlagService {
  private adiApiService: AdiApiService;

  features: Partial<Record<FeatureFlag, boolean>> | null;
  isFetching: boolean;

  constructor({ adiApiService }: FeatureFlagServiceProps) {
    this.adiApiService = adiApiService;
    this.features = null;
    this.isFetching = false;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isUninitialized(): boolean {
    return this.features === null;
  }

  private _setFeatures(features: FeatureFlagService['features']) {
    this.features = features;
  }

  private _toggleIsFetching(isFetching?: FeatureFlagService['isFetching']) {
    this.isFetching = isFetching || !this.isFetching;
  }

  isActive(featureId: FeatureFlag): boolean {
    return !!this.features?.[featureId];
  }

  refreshFeaturesFlags() {
    const featureFlagURL = API.FEATURE_FLAGS;
    this._toggleIsFetching();
    return this.adiApiService.axios
      .get(featureFlagURL)
      .then((response: AxiosResponse) => {
        const data: Partial<Record<FeatureFlag, boolean>> = response.data;
        this._setFeatures(data);
      })
      .catch((error) => {
        console.error(`Failed to fetch available feature flags at '${featureFlagURL}}'`);
        this._setFeatures(EMPTY_FLAGS);
      })
      .finally(() => this._toggleIsFetching());
  }
}
