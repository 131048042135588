/** @jsxImportSource @emotion/react */
import { ComponentPropsWithoutRef } from 'react';

import { observer } from 'mobx-react';

import { Button, ButtonProps } from '@mui/material';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

export const useStyles = makeStyles((theme) => ({
  root: {},
}));
export interface ApplyFilterButtonsProps extends ComponentPropsWithoutRef<'div'> {
  applyButtonType?: ButtonProps['type'];
  onReset?: () => void;
}

export const ApplyFilterButtons = observer(
  ({ className, applyButtonType, onReset, ...props }: ApplyFilterButtonsProps) => {
    const classes = useStyles(props);
    const { processingService } = useServices();
    const { currentModeFilters: filters } = processingService;
    const { timelineStartDate, timelineEndDate } = filters;

    return (
      <Stack className={clsx(classes.root, className)} direction="row" spacing={1}>
        <Button
          size="small"
          variant="contained"
          disabled={
            filters.disableApplyButton ||
            !filters.changeCounter ||
            !processingService.isValidDateRange([timelineStartDate, timelineEndDate], false)
          }
          type={applyButtonType}
        >
          <b>Apply</b>
        </Button>
        <Button size="small" onClick={onReset} disabled={filters.isDefaultValues([timelineStartDate, timelineEndDate])}>
          Reset
        </Button>
      </Stack>
    );
  }
);
