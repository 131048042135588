import { useServices } from 'services';

import { FeatureFlag } from 'utils/constants';

interface UseFeatureFlagProps {
  featureId: FeatureFlag;
}
export const useFeatureFlag = ({ featureId }: UseFeatureFlagProps) => {
  const { featureFlagService } = useServices();
  const flagEnabled = featureFlagService.isActive(featureId);

  return { flagEnabled };
};
