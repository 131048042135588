import { useEffect } from 'react';

import { observer } from 'mobx-react';

import { useServices } from 'services';

const Reprocessing = observer(() => {
  const { reprocessingService } = useServices();

  useEffect(() => {
    reprocessingService.initializeWizard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only initialize on first render

  return <div>Test Site</div>;
});

export default Reprocessing;
