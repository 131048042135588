import React from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { ConfirmationFormItemList } from 'utils/types';

import { ArmflowConfirmDialog } from '../common/ArmflowConfirmDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  runningText: {
    textAlign: 'center',
    padding: `${theme.spacing(2)} 0px`,
  },
}));

export interface PruneDataFormConfirmProps extends UseStyles<typeof useStyles>, ArmflowConfirmDialog {
  className?: string;
}

const PruneDataFormConfirm = observer((props: PruneDataFormConfirmProps): React.ReactElement | null => {
  const { className, open, onCancel, onClose, onSubmit } = props;
  const classes = useStyles(props);
  const { actionBarService, processingService } = useServices();

  const { disableConfirmButton } = actionBarService;
  const { pruneDataDialogForm } = processingService;
  const { processName, locationName, processingId, startDate, endDate } = pruneDataDialogForm;

  const formItems: ConfirmationFormItemList = [
    { name: 'PCM Process Name', value: processName },
    { name: 'Location', value: locationName },
    { name: 'Processing ID', value: processingId },
    { name: 'Start Date', value: startDate?.toISODate() ?? null },
    { name: 'End Date', value: endDate?.toISODate() ?? null },
  ];

  return (
    <div className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown') {
            actionBarService.setDisableConfirmButton(false);
            !!onClose && onClose(event, reason);
          }
        }}
      >
        <DialogTitle>Confirm Prune Data</DialogTitle>
        <DialogContentText px={3} pb={2} fontSize={14} lineHeight={'180%'} width={500}>
          <b>Disclaimer:</b> <br />
          User can only prune days that have not been released to the archive. <br />
          All the output files produced for those days will be deleted. <br />
          The DSDB output datastream dates will be cleared. <br />
          The recorded days will be deleted from the ARMFlow database. <br />A message will be appended to the process
          log file recording which days were pruned.
        </DialogContentText>

        <DialogContent dividers>
          <Grid container width={450} justifyContent={'space-between'} rowGap={'1rem'}>
            {formItems.map((entry) => (
              <Grid container xs={12} justifyContent={'inherit'} key={entry.name}>
                <Typography>{entry.name}:</Typography>
                <Typography>{entry.value}</Typography>
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        {/* Action buttons */}
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onSubmit} disabled={disableConfirmButton} color="primary" variant="outlined">
            Prune Data
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default PruneDataFormConfirm;
