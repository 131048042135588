import React from 'react';

import { observer } from 'mobx-react';

import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormGroup } from '@mui/material';

import clsx from 'clsx';
import _ from 'lodash';

import { useServices } from 'services';

import { ToggleFilterKeys } from 'models/Filters';

import { useStyles } from '../definitions/styles';
import { FilterFieldProps } from '../definitions/types';

export type ToggleFilterProps = FilterFieldProps<CheckboxProps['onChange']> & {
  label: string;
  activeKey: ToggleFilterKeys;
};

export const ToggleFilter = observer((props: ToggleFilterProps) => {
  const classes = useStyles(props);
  const { processingService } = useServices();
  const { activeKey, label, onChange } = props;

  const filters = processingService.currentModeFilters;
  const active = filters[activeKey];

  return (
    <FormControl component="fieldset" className={clsx(classes.formControl, classes.toggleButton)}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={active} onChange={onChange} name={_.kebabCase(activeKey)} />}
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
});
