import { ComponentPropsWithoutRef } from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { FormikHelpers, useFormik } from 'formik';

import { useServices } from 'services';

import AutocompleteLocation from 'components/autocomplete/AutocompleteLocation';
import AutocompleteProcessName from 'components/autocomplete/AutocompleteProcessName';
import { FeatureWrapper } from 'components/utility/featureFlags/FeatureWrapper';
import { useArmflowFormSubmitter } from 'hooks/useArmflowFormSubmitter';
import RunProcessForm, { RUN_PROCESS_EMPTY_FORM, RunProcessFormFields } from 'models/RunProcessForm';
import { ARMFlowForms, FeatureFlag, ModalType, ProcessType } from 'utils/constants';

import { ArmflowFormDialog, useArmflowFormUpdater } from '../common/ArmflowFormDialog';
import AutocompleteProcessingId from '../fields/AutocompleteProcessingId';
import FormDateRangePicker from '../fields/FormDateRangePicker';
import FormHistory from '../fields/FormHistory';
import { MonospaceTextField } from '../fields/MonospaceTextField';
import ValidationBox from '../fields/ValidationBox';
import ResetButton from '../form/ResetButton';
import RunProcessFormConfirm from './RunProcessFormConfirm';
import { RunProcessFormTokens } from './schema';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  formDialog: {
    width: 550,
  },
  formControl: {
    padding: theme.spacing(1, 0),
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export interface RunProcessFormDialogProps extends ArmflowFormDialog, ComponentPropsWithoutRef<'div'> {}

export const RunProcessFormDialog = observer(({ ..._props }: RunProcessFormDialogProps) => {
  const { className, validatorPath, modalInfo, ...props } = _props;
  const { handleCloseForm, closeConfirmation, confirmSubmit } = useArmflowFormSubmitter({
    formType: ARMFlowForms.RUN_PROCESS,
    validatorPath,
  });

  const classes = useStyles(props);
  const { actionBarService, adiApiService, processingService } = useServices();

  const handleSubmit = (values: RunProcessFormFields, formikHelpers: FormikHelpers<RunProcessFormFields>) => {
    const {
      processName,
      processType,
      locationName,
      processingId,
      startDate,
      endDate,
      isReprocessing,
      commandLineArgs,
    } = values;

    processingService.runProcessDialogForm.updateForm({
      processName,
      processType,
      locationName,
      processingId,
      isReprocessing,
      commandLineArgs,

      // If selected process is an Ingest, don't pass any dates as they're irrelevant for Ingests
      startDate: processType !== ProcessType.INGEST ? startDate : null,
      endDate: processType !== ProcessType.INGEST ? endDate : null,
    });

    // Pre-confirm form validation
    processingService
      .validateForm(validatorPath, ARMFlowForms.RUN_PROCESS, processingService.runProcessDialogForm)
      .then(() => formikHelpers.setSubmitting(false));
  };

  const { openFormConfirms, validationLoading } = actionBarService;

  // const formOpen = openForms[ARMFlowForms.RUN_PROCESS];
  const formOpen = modalInfo.type === ModalType.Form && modalInfo.conditionalKey === ARMFlowForms.RUN_PROCESS; // TODO: (Elvis, 2024/12/10) is this check necessary? This component should only render when the form's already opened
  const confirmationOpen = openFormConfirms[ARMFlowForms.RUN_PROCESS];

  const runProcessDialogForm = processingService.runProcessDialogForm;

  const initialValues = {
    processName: runProcessDialogForm.processName,
    processType: runProcessDialogForm.processType,
    locationName: runProcessDialogForm.locationName,
    processingId: runProcessDialogForm.processingId,
    startDate: runProcessDialogForm.startDate,
    endDate: runProcessDialogForm.endDate,
    isReprocessing: runProcessDialogForm.isReprocessing,
    commandLineArgs: runProcessDialogForm.commandLineArgs,
  };

  const { formIds, validationSchema } = RunProcessFormTokens;

  // Initialize Formik
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    initialValues: initialValues,
  });

  const { values, touched, errors, setFieldValue, setFieldTouched, submitCount, isSubmitting } = formik;

  // Get special handler function for updating our Formik forms
  const handleFormUpdate = useArmflowFormUpdater(setFieldValue, setFieldTouched, submitCount);

  return (
    <div className={clsx(classes.root, className)} {...props}>
      {confirmationOpen && (
        <RunProcessFormConfirm
          open={confirmationOpen}
          onClose={closeConfirmation}
          onCancel={closeConfirmation}
          onSubmit={confirmSubmit}
        />
      )}

      <Dialog
        open={formOpen}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown') {
            handleCloseForm();
          }
        }}
      >
        <form className={classes.formDialog} onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogTitle className={classes.dialogTitle}>
            Run Ingest/VAP
            <Stack direction="row">
              <FormHistory
                disabled={validationLoading}
                form={ARMFlowForms.RUN_PROCESS}
                onSelected={(formJSON) => {
                  const converted = RunProcessForm.fromJSON(formJSON);
                  formik.setValues({ ...values, ...converted });
                }}
              />
              <ResetButton
                disabled={isSubmitting}
                onClick={() => {
                  formik.resetForm({ values: RUN_PROCESS_EMPTY_FORM });
                  runProcessDialogForm.reset();
                  adiApiService.clearValidatorError(validatorPath);
                }}
              />
            </Stack>
          </DialogTitle>

          <DialogContent dividers>
            {/* PCM Process Name selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteProcessName
                  id={formIds.processName}
                  value={values.processName}
                  onChange={(value, processType) => {
                    handleFormUpdate([
                      { formId: formIds.processName, value },
                      { formId: formIds.locationName, value: null, skipTouch: true }, // Clear location field when process name changes
                      { formId: formIds.processType, value: processType },

                      // Reset reprocessing/run-bundler flag depending on chosen process
                      ...(processType === ProcessType.INGEST ? [{ formId: formIds.isReprocessing, value: null }] : []),
                    ]);
                  }}
                  error={touched.processName && Boolean(errors.processName)}
                  helperText={touched.processName && errors.processName}
                  disabled={isSubmitting}
                />
              </FormControl>
            </FormGroup>

            {/* Location selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteLocation
                  id={formIds.locationName}
                  disabled={!values.processName || isSubmitting}
                  processName={values.processName}
                  value={values.locationName}
                  onChange={(_, value) => handleFormUpdate({ formId: formIds.locationName, value })}
                  error={touched.locationName && Boolean(errors.locationName)}
                  helperText={touched.locationName && errors.locationName}
                />
              </FormControl>
            </FormGroup>

            <FeatureWrapper featureId={FeatureFlag.REPROCESSING}>
              {/* Reprocessing Toggle (VAP only) */}
              {values.processType === ProcessType.VAP && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={formIds.isReprocessing}
                        checked={values.isReprocessing}
                        disabled={isSubmitting}
                        onChange={(_, isReprocessing) =>
                          handleFormUpdate({ formId: formIds.isReprocessing, value: isReprocessing })
                        }
                        name="toggle-reprocessing"
                      />
                    }
                    label="Reprocessing"
                  />
                </FormGroup>
              )}

              {/* Processing ID selection */}
              {values.isReprocessing && values.processType === ProcessType.VAP ? (
                <FormGroup>
                  <FormControl required={values.isReprocessing} className={classes.formControl}>
                    <AutocompleteProcessingId
                      allowNewIfNonexistent
                      disabled={isSubmitting}
                      processName={values.processName}
                      locationName={values.locationName}
                      isReprocessing={values.isReprocessing}
                      id={formIds.processingId}
                      value={values.processingId}
                      onChange={(_, value) => handleFormUpdate({ formId: formIds.processingId, value })}
                      error={touched.processingId && Boolean(errors.processingId)}
                      helperText={touched.processingId && errors.processingId}
                    />
                  </FormControl>
                </FormGroup>
              ) : null}
            </FeatureWrapper>

            {/* Start/End Date pickers */}
            {values.processType !== ProcessType.INGEST && (
              <FormGroup>
                <FormControl className={classes.formControl}>
                  <FormDateRangePicker
                    value={[values.startDate, values.endDate]}
                    startText="Start"
                    endText="End"
                    disabled={isSubmitting}
                    startHelperText={touched.startDate && errors.startDate}
                    endHelperText={touched.endDate && errors.endDate}
                    startError={touched.startDate && Boolean(errors.startDate)}
                    endError={touched.endDate && Boolean(errors.endDate)}
                    onChange={(newValue) => {
                      const [startDate, endDate] = newValue;

                      // check if start/end are different from stored; set skipTouch accordingly
                      const skipStartTouch = startDate?.toISODate() === values.startDate?.toISODate();
                      const skipEndTouch = endDate?.toISODate() === values.endDate?.toISODate();
                      handleFormUpdate([
                        { formId: formIds.startDate, value: startDate, skipTouch: skipStartTouch },
                        { formId: formIds.endDate, value: endDate, skipTouch: skipEndTouch },
                      ]);
                    }}
                  />
                </FormControl>
              </FormGroup>
            )}

            <FormGroup>
              <FormControl className={classes.formControl}>
                <MonospaceTextField
                  id={formIds.commandLineArgs}
                  value={values.commandLineArgs}
                  error={touched.commandLineArgs && Boolean(errors.commandLineArgs)}
                  helperText={touched.commandLineArgs && errors.commandLineArgs}
                  onChange={(event) =>
                    handleFormUpdate({ formId: formIds.commandLineArgs, value: event.target.value ?? null })
                  }
                  label="Optional args"
                  variant="outlined"
                  disabled={isSubmitting}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={'Custom command line arguments'}
                />
              </FormControl>
            </FormGroup>

            {/* Pre-confirm form validation */}
            <ValidationBox apiPath={validatorPath} />
          </DialogContent>

          {/* Action buttons */}
          <DialogActions>
            <Button onClick={handleCloseForm}>Cancel</Button>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              disabled={isSubmitting || (!formik.isValid && formik.submitCount > 0)}
            >
              Run
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
});
