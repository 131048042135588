import React from 'react';

import { Button, ButtonProps } from '@mui/material';

import { LinkProps, useHref, useLinkClickHandler } from 'react-router';

interface LinkButtonProps extends Omit<ButtonProps, 'href'> {
  to: LinkProps['to'];
  linkProps?: Omit<LinkProps, 'to'>;
}

export const LinkButton = ({ onClick, to, linkProps, ...props }: LinkButtonProps) => {
  const { replace = false, state, target } = linkProps || {};

  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  });

  return (
    <Button
      {...props}
      href={href}
      onClick={(event) => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event as React.MouseEvent<HTMLAnchorElement & HTMLButtonElement, MouseEvent>);
        }
      }}
    />
  );
};
