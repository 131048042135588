import React from 'react';

import { observer } from 'mobx-react';

import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { Link } from 'react-router';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import { AppRoutes } from 'utils/routes';

import ArmFlowLogoPng2 from '../assets/images/Logo2-transparent.svg';
import { AccountMenu } from './menus/AccountMenu';
import ExternalLinkMenu from './menus/ExternalLinkMenu';

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logoContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
    },
    logo: {
      height: 80,
    },
    title: {
      position: 'relative',
      zIndex: 100,
      fontFamily: 'Montserrat',
      fontSize: 32,
      fontWeight: 600,
      color: 'white',
      marginLeft: '-4rem',
    },
    titleColor1: {
      color: 'white',
      textShadow: '-1px 1px 5px #121212, 1px 1px 5px #121212',
    },
    titleColor2: {
      color: '#8e8e8e',
    },
    menuContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  });

export interface IAppProps extends WithServicesProps {
  classes: {
    appBar: string;
    toolbar: string;
    logoContainer: string;
    logo: string;
    title: string;
    titleColor1: string;
    titleColor2: string;
    menuContainer: string;
  };
}

class Navbar extends React.Component<IAppProps> {
  render() {
    const { classes } = this.props;
    return (
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {/* Logo & Title */}
          <Link to={AppRoutes.Dashboard} className={classes.logoContainer}>
            <img className={classes.logo} alt="armflow logo" src={ArmFlowLogoPng2} />
            <Typography variant="h4" className={classes.title}>
              <b className={classes.titleColor1}>ARM</b>
              <b className={classes.titleColor2}>Flow</b> Dashboard
            </Typography>
          </Link>

          <Box className={classes.menuContainer}>
            <AccountMenu />
            <ExternalLinkMenu />
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withServices(observer(Navbar)));
