/** @jsxImportSource @emotion/react */
import { CircularProgress, css } from '@mui/material';

export const LoadingPage = () => (
  <div css={layoutStyle}>
    <CircularProgress size={50} />
  </div>
);

const layoutStyle = css`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
