import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { useServices } from 'services';

import { BiggerLoadingSpinner } from 'components/common/BiggerLoadingSpinner';
import { RefreshButton } from 'components/common/RefreshButton';
import { Text } from 'components/styles';
import ProcessStateTable from 'components/timeline/processStateButton/table/ProcessStateTable';
import { transformProcessStateData } from 'components/timeline/processStateButton/table/transform';
import { RunModeDetailsResponse } from 'utils/types';

import { ModalTitle } from './ModalTitle';
import { ModalDialogProps } from './props';
import { useModalStyles } from './styles';

export interface ProcessStatesModalProps extends ComponentPropsWithoutRef<'div'> {
  /* Additional props go here */
}

export const ProcessStatesModal = observer(({ modalInfo, onModalClose, ...props }: ModalDialogProps) => {
  const classes = useModalStyles(props);
  const { processingService } = useServices();
  const { processStatesData } = processingService;

  const { id: modalId, title, subtitle } = modalInfo;
  const pipelineId = processStatesData?.pipelineId;

  const [isLoading, setIsLoading] = useState(false);
  const [runModeDetails, setRunModeDetails] = useState<RunModeDetailsResponse | null>(null);

  const [isProcessStateModified, setIsProcessStateModified] = useState(false);

  // Table state info
  const [selected, setSelected] = useState<readonly number[]>([]);

  const refreshProcessStateData = () => {
    if (pipelineId) {
      setIsLoading(true);
      processingService
        .fetchRunModeDetails(pipelineId)
        .then((details) => details && setRunModeDetails(details))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    refreshProcessStateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformedData = useMemo(() => transformProcessStateData(runModeDetails), [runModeDetails]);

  const handleCloseModal = () => {
    if (onModalClose) {
      onModalClose(modalId);
    }

    // Reload timeline data on-close to refresh the current pipelines' states
    if (isProcessStateModified) {
      processingService.refresh();
    }
  };

  const handleSetSelection = (items: readonly number[]) => {
    setSelected(items);
  };

  const handlePostSubmit = () => {
    if (!isProcessStateModified) {
      setIsProcessStateModified(true);
    }

    // Reload table data after submitting
    refreshProcessStateData();
  };

  const refreshButton = (
    <RefreshButton tooltipText="Refresh table" placement="left" onClick={() => refreshProcessStateData()} />
  );

  return (
    <Dialog open={true} className={classes.log} fullWidth maxWidth="lg" scroll="paper" onClose={handleCloseModal}>
      {/* Title */}
      <ModalTitle modalTitle={title} modalSubtitle={subtitle} adornment={refreshButton} />

      {/* Text */}
      <DialogContent>
        {isLoading ? (
          <BiggerLoadingSpinner />
        ) : !pipelineId ? (
          <Text>No Pipeline ID is defined</Text>
        ) : (
          <ProcessStateTable
            cy-test={'process-state-table'}
            pipelineId={pipelineId}
            data={transformedData}
            selectedRows={selected}
            onSetSelected={handleSetSelection}
            onSubmitted={handlePostSubmit}
          />
        )}
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Button onClick={handleCloseModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
});
