import { Cancel } from '@mui/icons-material';

import { GoHomeButton } from './GoHomeButton';
import { NonIdealState } from './NonIdealState';

export const PageNotFound = () => (
  <NonIdealState
    icon={<Cancel fontSize="inherit" />}
    title="404: Page Not Found"
    description="Nothing to see here!"
    action={GoHomeButton}
  />
);
