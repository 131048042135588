import { API } from './constants';

type GenerateReprocessingStepPathArgs = {
  basePath: string;
  ewo?: string;
  stepId?: string;
  useSearchString?: boolean;
};

function _generateReprocessingStepPath({
  basePath,
  ewo,
  stepId,
  useSearchString,
}: GenerateReprocessingStepPathArgs): string {
  const ewoPart = ewo ? `/${ewo}` : '';
  const showStepId = ewo && stepId; // Only output stepId if `ewo` is valid string

  let stepIdPart: string = showStepId ? `/${stepId}` : '';

  // Return step ID as query string w/ flag raised
  if (showStepId && useSearchString) {
    stepIdPart = `?stepId=${stepId}`;
  }

  return `${basePath}${ewoPart}${stepIdPart}`;
}

/** Generates URL for logging into ARMFlow. Note that the `/login` navigates to the Airflow authentication. */
export function generateLoginURL(): string {
  const { PUBLIC_URL } = process.env;
  return `/login/?next=${PUBLIC_URL || '/'}`;
}

export function generateAPIReprocessingStepUrl(ewo?: string, stepId?: string): string {
  return _generateReprocessingStepPath({ basePath: API.REPROCESSING_PREFIX, ewo, stepId });
}

export const AppRoutes: ArmflowSiteMap = {
  Dashboard: '/',
  Reprocessing: {
    Root: '/reprocessing',
    Wizard: (ewo, stepId) =>
      _generateReprocessingStepPath({ basePath: AppRoutes.Reprocessing.Root, ewo, stepId, useSearchString: true }),
  },
};

type ArmflowSiteMap = {
  Dashboard: string;
  Reprocessing: {
    Root: string;
    Wizard: (ewo?: string, stepId?: string) => string;
  };
};
