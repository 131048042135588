import { makeAutoObservable } from 'mobx';

import { Step, StepStatusRequest, WizardBreadcrumbId, WizardSteps } from 'services/reprocessing/types';

import { DEFAULT_WIZARD_STEPS } from 'utils/constants';

import AdiApiService from '../AdiApiService';

type ReprocessingServiceProps = {
  adiApiService: AdiApiService;
};

export default class ReprocessingService {
  private adiApiService: AdiApiService;

  reprocessingEWO: string | null;
  wizardSteps: WizardSteps | null;

  constructor({ adiApiService }: ReprocessingServiceProps) {
    this.adiApiService = adiApiService;
    this.reprocessingEWO = null;
    this.wizardSteps = null;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  setReprocessingEWO(ewo: ReprocessingService['reprocessingEWO']) {
    this.reprocessingEWO = ewo;
  }

  setWizardSteps(steps: ReprocessingService['wizardSteps']) {
    this.wizardSteps = steps;
  }

  initializeWizard() {
    this.resetWizard();
  }

  resetWizard() {
    this.setWizardSteps([...DEFAULT_WIZARD_STEPS]);

    // For each step:
    //   - Check if children exist
    //   - For each child, 1) track current parent and 2) assign parent step to child's `parent` attribute
    //   - Repeat for any existing children (either loop or use recursion)
    this.wizardSteps?.forEach((step) => this.connectChildrenToParents(step));
  }

  /** Conduct depth-first traversal for a `Step`'s `children` (and children's children)
   *  assigning each child's `parent` attribute */
  private connectChildrenToParents(parentStep: Step) {
    parentStep.children?.forEach((child) => {
      // Remove children
      const { children, ...parentWithoutChildren } = parentStep;
      child.parent = parentWithoutChildren;

      // Base case
      if (!child.children || !child.children.length) {
        return;
      }

      // Connect the child's children to child (recursively)
      this.connectChildrenToParents(child);
    });
  }

  /**
   * Returns list of request required to populate the current view
   * @todo Convert to getter (`get createStatusRequestList():`)
   */
  createStatusRequestList(): StepStatusRequest[] {
    // At minimum, the stepper status will be returned
    const requests: string[] = [WizardBreadcrumbId.STEPPER];

    // Any additional request will depend on the current step

    // Initialize base request (overall stepper status)
    return [];
  }

  // fetchStepStatus(stepId: Step) {}
}
