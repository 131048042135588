export enum WizardBreadcrumbId {
  STEPPER = 'stepper',
}

export enum OverallStep {
  // Overall
  PUSH_VAP_TO_PRODUCTION = 'push-vap-to-production',
  REGISTER_EWO_IN_ARMFLOW = 'register-ewo-in-armflow',
  COMPLETE_DOCUMENT_REPROCESSING_MOD = 'complete-document-reprocessing-mod',
  REPROCESS_PIPELINES = 'reprocess-pipelines',
  CLOSE_EWO = 'close-ewo',
}

export enum ModStep {
  // Complete Document Reprocessing MOD
  CDR_RUN_PIPELINE = 'cdr-run-pipeline',
  CDR_SIGNIFICANT_CHANGE = 'cdr-significant-change',
  CDR_REVIEW_DATA = 'cdr-review-data',
  CDR_CREATE_RELEASE_NOTICE = 'cdr-create-release-notice',
  CDR_UPDATE_TECHNICAL_REPORT = 'cdr-update-technical-report',
  CDR_REVIEW_RELEASE_NOTICE = 'cdr-review-release-notice',
  CDR_REVIEW_TECHNICAL_REPORT = 'cdr-review-technical-report',
  CDR_COMPLETE_MOD = 'cdr-complete-mod',
}

export enum ReprocessPipelinesStep {
  // Reprocess Pipelines
  RP_REPROCESS_DATA = 'rp-reprocess-data',
  RP_SUBMIT_DQR = 'rp-submit-dqr',
  RP_RD_CREATE_EWO = 'rp-rd-create-ewo',
  RP_RD_CHECK_METADATA = 'rp-rd-check-metadata',
  RP_RD_RELEASE_DATA = 'rp-rd-release-data',
  RP_RD_REVIEW_VAP_DATA_DISCOVERY = 'rp-rd-review-vap-data-discovery',
}

export type StepType = OverallStep | ModStep | ReprocessPipelinesStep;

export type Step = {
  stepId: string;
  name: string;
  type: StepType;

  /** Used for constructing request list  */
  parent?: Step;
  children?: Step[];
};

export type WizardSteps = [Step, Step, Step, Step, Step];

export type StepStatusRequest = {
  endpoint: string;
  responseHandler: () => unknown;
};

// export enum StepType {
//   // Overall
//   PUSH_VAP_TO_PRODUCTION = 'push-vap-to-production',
//   REGISTER_EWO_IN_ARMFLOW = 'register-ewo-in-armflow',
//   COMPLETE_DOCUMENT_REPROCESSING_MOD = 'complete-document-reprocessing-mod',
//   REPROCESS_PIPELINES = 'reprocess-pipelines',
//   CLOSE_EWO = 'close-ewo',

//   // Complete Document Reprocessing MOD
//   CDR_RUN_PIPELINE = 'cdr-run-pipeline',
//   CDR_SIGNIFICANT_CHANGE = 'cdr-significant-change',
//   CDR_REVIEW_DATA = 'cdr-review-data',
//   CDR_CREATE_RELEASE_NOTICE = 'cdr-create-release-notice',
//   CDR_UPDATE_TECHNICAL_REPORT = 'cdr-update-technical-report',
//   CDR_REVIEW_RELEASE_NOTICE = 'cdr-review-release-notice',
//   CDR_TECHNICAL_REPORT = 'cdr-technical-report',
//   CDR_COMPLETE_MOD = 'cdr-complete-mod',

//   // Reprocess Pipelines
//   RP_REPROCESS_DATA ='rp-reprocess-data',
//   RP_SUBMIT_DQR ='rp-submit-dqr',
//   RP_RD_CREATE_EWO ='rp-rd-create-ewo',
//   RP_RD_CHECK_METADATA ='rp-rd-check-metadata',
//   RP_RD_RELEASE_DATA ='rp-rd-release-data',
//   RP_RD_REVIEW_VAP_DATA_DISCOVERY ='rp-rd-review-vap-data-discovery',
// }
