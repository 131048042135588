import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import { WithTheme as WithThemeProps } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { DateTime } from 'luxon';

import { withServices } from 'services';
import { WithServicesProps } from 'services/withServices';

import { API, ARMFlowForms, CONFIRM_FORM_FALLBACK_TEXT, ProcessType } from 'utils/constants';
import { ConfirmationFormItem } from 'utils/types';

import { ArmflowConfirmDialog } from '../common/ArmflowConfirmDialog';
import ConfirmDateEdit, { ConfirmDateEditProps } from '../form/ConfirmDateEdit';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    content: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    runningText: {
      textAlign: 'center',
      padding: `${theme.spacing(2)} 0px`,
    },
    loading: {
      paddingRight: '3rem',
    },
    dateLine: {
      height: 30,
      width: '100%',
      padding: '0 3rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    datePicker: {
      textAlign: 'end',
    },
    unsetValue: {
      color: grey[500],
    },
    footnote: {
      paddingTop: '0.5rem',
    },
    subtitle: {
      paddingBottom: '20px',
    },
  });

export interface RunProcessFormConfirmProps extends WithServicesProps, WithThemeProps, ArmflowConfirmDialog {
  filesToRelease?: string[];
  classes: {
    root: string;
    content: string;
    runningText: string;
    loading: string;
    dateLine: string;
    datePicker: string;
    unsetValue: string;
    footnote: string;
    subtitle: string;
  };
}

interface RunProcessFormConfirmState {
  startDate: string | null;
  endDate: string | null;
  warnings: string[] | null;
  datesLoaded: boolean;
  dateWasEdited: boolean;
}

type RunnableDates = {
  begin: string | null;
  end: string | null;
  warnings: string[] | null;
};

interface RunProcessConfirmItem extends ConfirmationFormItem {
  fieldName: ConfirmDateEditProps['fieldName'];
}

class RunProcessFormConfirm extends React.Component<RunProcessFormConfirmProps, RunProcessFormConfirmState> {
  constructor(props: Readonly<RunProcessFormConfirmProps>) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      warnings: null,
      datesLoaded: false,
      dateWasEdited: false,
    };
  }

  // TODO: The logic calling the API should be handled in `ProcessingService`
  componentDidMount() {
    const { services } = this.props;
    const { processingService, adiApiService } = services;
    const { runProcessDialogForm, processTypes } = processingService;
    const { processName, locationName, startDate, endDate } = runProcessDialogForm;

    const processType = processTypes[processName || ''];
    const isIngest = processType === ProcessType.INGEST;
    if (isIngest) {
      this.setState({ datesLoaded: true });
    } else {
      // If this is a VAP, call web service to find the dates will run based upon available data
      let url: string = `${API.RUNNABLE_DATES}?process_name=${processName}&location=${locationName}`;
      if (startDate && endDate) {
        const b = startDate.toISODate();
        const e = endDate.toISODate();
        url += `&start_date=${b}&end_date=${e}`;
      }
      adiApiService.axios
        .get(url)
        .then((response) => {
          const data: RunnableDates = response.data;
          this.setState({ startDate: data.begin, endDate: data.end, warnings: data.warnings, datesLoaded: true });

          // TODO: when cancelling from here, begin/end date will still be in form model state
          //       Need to clear/sync on-cancel so these dates aren't passed in on re-submission
          const beginLuxon = data.begin ? DateTime.fromISO(data.begin) : null;
          const endLuxon = data.end ? DateTime.fromISO(data.end) : null;
          runProcessDialogForm.updateDate({ begin: beginLuxon, end: endLuxon }); // Update form with server-returned dates
        })
        .catch((error) => {
          adiApiService.handleError(error, {
            id: 'error-finding-run-dates',
            title: 'Run Date Lookup Error',
            message: 'An error occurred trying to look up run dates.',
          });
          this.setState({ datesLoaded: true });
        });
    }
  }

  render(): React.ReactNode {
    const { classes, services, theme } = this.props;
    const { open, onCancel, onClose, onSubmit } = this.props;
    const { actionBarService, processingService, storageService } = services;
    const { disableConfirmButton } = actionBarService;
    const { runProcessDialogForm, processTypes } = processingService;
    const { processName, locationName, isReprocessing } = runProcessDialogForm;

    const formItems: RunProcessConfirmItem[] = [
      { fieldName: 'start', name: 'Start Date', value: this.state.startDate ?? null },
      { fieldName: 'end', name: 'End Date', value: this.state.endDate ?? null },
    ];

    const processType = processTypes[processName || ''];
    const isIngest = processType === ProcessType.INGEST;

    const disableRunButton = isIngest ? false : !this.state.datesLoaded || this.state.startDate === null;
    let showFootnote: boolean;
    let footnoteText: string;
    let missingDateComponent: ReactNode;

    if (isIngest) {
      showFootnote = true;
      footnoteText =
        '* Dates will be automatically computed based upon available data.  Note that if no data are available, nothing will run.';
      missingDateComponent = <span className={classes.unsetValue}>{`${CONFIRM_FORM_FALLBACK_TEXT} *`}</span>;
    } else if (!this.state.datesLoaded) {
      // This is a VAP and we haven't loaded the dates yet
      showFootnote = false;
      footnoteText = '';
      missingDateComponent = (
        <span className={classes.loading}>
          <CircularProgress size={theme.typography.fontSize} />
        </span>
      );
    } else {
      // This is VAP and we got the run dates
      showFootnote = !this.state.startDate; // if run dates are still null, then we show footnote
      footnoteText = '* Not enough input data are available to run.';
      missingDateComponent = <span className={classes.unsetValue}>Not available *</span>;
    }

    const handleSubmitForm = (event: React.SyntheticEvent<any, Event>) => {
      const { startDate, endDate, dateWasEdited } = this.state;

      // Bail if anything is null, empty, or undefined
      if (!onSubmit) return;
      if (!isIngest && !(startDate && endDate)) return;

      if (dateWasEdited) {
        // Update storageService's form history entry with new date if modified
        storageService.addFormToHistory(ARMFlowForms.RUN_PROCESS, runProcessDialogForm, true);
      }

      onSubmit(event);
    };

    return (
      <div className={classes.root}>
        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason === 'escapeKeyDown') {
              actionBarService.setDisableConfirmButton(false);
              !!onClose && onClose(event, reason);
            }
          }}
        >
          <DialogTitle>
            <span>
              {isReprocessing ? 'Reprocess ' : 'Run '}
              {isIngest ? 'Ingest ' : 'VAP '} {processName} at {locationName}{' '}
            </span>
          </DialogTitle>

          <DialogContent dividers>
            <Typography className={classes.subtitle}>
              Based upon available data and processing rules, ARMFlow will try to run the following dates:
            </Typography>
            <Stack width={'100%'} alignItems={'center'} gap={'1rem'}>
              {formItems.map((entry) => {
                const { name, value, fieldName } = entry;
                const isValueSet = value !== null;
                return (
                  <div className={classes.dateLine} key={name}>
                    <Typography>{name}:</Typography>
                    {isValueSet ? (
                      <ConfirmDateEdit
                        className={classes.datePicker}
                        fieldName={fieldName}
                        dateRange={[
                          this.state.startDate ? runProcessDialogForm.startDate : null,
                          this.state.endDate ? runProcessDialogForm.endDate : null,
                        ]}
                        tooltipText={`Edit ${name.toLowerCase()}`}
                        onChange={(newValue) => {
                          const [startDate, endDate] = newValue;
                          if (!this.state.dateWasEdited) this.setState({ dateWasEdited: true });
                          runProcessDialogForm.updateDate({ begin: startDate, end: endDate });
                        }}
                      />
                    ) : (
                      missingDateComponent
                    )}
                  </div>
                );
              })}
              {showFootnote && (
                <Typography variant="caption" className={classes.footnote}>
                  {footnoteText}
                </Typography>
              )}
              {this.state.datesLoaded &&
                this.state.warnings &&
                this.state.warnings.map((value, idx) => (
                  <Typography key={idx} className={classes.footnote}>
                    ==={'>'} {value}
                  </Typography>
                ))}
            </Stack>
          </DialogContent>

          {/* Action buttons */}
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              // onClick={onSubmit}
              onClick={handleSubmitForm}
              disabled={disableConfirmButton || disableRunButton}
              color="primary"
              variant="outlined"
            >
              Run
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withServices(observer(RunProcessFormConfirm)));
