/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { Button, css, Paper, Stack, styled, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { useServices } from 'services';

import { GeneralAutocomplete } from 'components/common/GeneralAutocomplete';
import { LabeledTextField } from 'components/common/LabeledTextField';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Text } from 'components/styles';
import { formDisabledStyle, formEnabledStyle } from 'hooks/useBulkActionHandler';
import { LabelToRunMode, RunMode, RunModeToLabel, TABLE_SECTION_HORIZONTAL_SPACING } from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';

import { ProcessStateDescriptionButton } from '../descriptionTooltip/ProcessStateDescriptionButton';
import { EnhancedTable } from './EnhancedTable';
import { processStateIsEditable } from './tableUtils';
import { HeadCell, ProcessStateTableData } from './types';

const VALID_RUN_MODES: RunMode[] = [RunMode.AUTO, RunMode.MANUAL, RunMode.DEPRECATED, RunMode.LOCKED];

const PROCESS_STATE_OPTIONS = VALID_RUN_MODES.map((mode) => RunModeToLabel[mode]);

export interface ProcessStateTableProps {
  pipelineId: PipelineIdentifier;
  data: ProcessStateTableData[];

  selectedRows: readonly number[];
  onSetSelected: (selected: readonly number[]) => void;
  onSubmitted?: () => void;
}

/**
 * @todo Do we want a confirmation dialog?
 */
const ProcessStateTable = observer(
  ({ pipelineId, data, selectedRows: selected, onSetSelected: setSelected, onSubmitted }: ProcessStateTableProps) => {
    const [newReason, setNewReason] = useState<string | null>(null);
    const [selectedRunMode, setSelectedRunMode] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const theme = useTheme();
    const { processingService } = useServices();

    const disableReasonField = !selected.length;
    const editableData = useMemo(() => data.filter((processStates) => processStateIsEditable(processStates)), [data]);

    const clearFields = useCallback(() => {
      setNewReason(null);
      setSelectedRunMode('');
    }, []);

    const clearSelection = () => {
      setSelected([]);
    };

    useEffect(() => {
      if (!selected.length) {
        clearFields();
      }
    }, [clearFields, selected.length]);

    const handleResetRunModeChange = () => {
      clearFields();
      clearSelection();
    };

    const handleSubmitModeChange = async (value: RunMode | null) => {
      setIsSubmitting(true);
      await processingService.sleep(500); // artificial wait to feel like something's happening

      const selectedProcessTypes = selected
        .map((id) => editableData.find((value) => value.id === id)?.processType ?? '')
        .filter((value) => !!value);

      try {
        if (!value) {
          console.warn(`Submitted run mode value '${value}' is not a valid run mode.`);
          return;
        }

        await processingService.submitRunModeChange(value, pipelineId, selectedProcessTypes, newReason?.trim() ?? '');

        // Run post-submission methods
        setIsSubmitting(false);
        clearFields();
        clearSelection();
        if (onSubmitted) {
          onSubmitted();
        }
      } catch (error) {
        console.error('ProcessStateTable: Error encountered while submitting new.');
      }
    };

    return (
      <Stack sx={{ width: '100%' }} gap={'0.5rem'}>
        {/* Process State Table */}
        <EnhancedTable<ProcessStateTableData>
          data={data}
          headers={HeadCells}
          tableTitle={
            <Stack direction="row" alignItems="center" gap="0.25rem">
              <ProcessStateDescriptionButton />
              Current Process States
            </Stack>
          }
          selectedRowsState={{
            selectedRows: selected,
            setSelectedRows: setSelected,
          }}
          noDataText="No Pipeline States"
          editable={{
            isEditableFunc: processStateIsEditable,
            readOnlyText: (row) => `Process States for "${row.processType}" are read-only`,
          }}
          columnProps={{
            processType: { component: 'th', scope: 'row', padding: 'none' },
            description: { sx: { maxWidth: 500 } },
          }}
        />

        {/* Process State Change fields */}
        <Text
          css={{
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(TABLE_SECTION_HORIZONTAL_SPACING),
            color: disableReasonField ? grey[500] : undefined,
          }}
        >
          Change State for Selected Processes
        </Text>

        <Paper elevation={2}>
          {
            <form
              css={[formStyle, disableReasonField ? formDisabledStyle : formEnabledStyle]}
              onSubmit={(e) => {
                e.preventDefault();
                return handleSubmitModeChange(
                  selectedRunMode && selectedRunMode in LabelToRunMode ? LabelToRunMode[selectedRunMode] : null
                );
              }}
            >
              <RunModeSelectContainer>
                <ReasonFieldsContainer>
                  <GeneralAutocomplete
                    label="New State"
                    value={selectedRunMode}
                    disabled={disableReasonField || isSubmitting}
                    size="small"
                    options={PROCESS_STATE_OPTIONS}
                    onChange={setSelectedRunMode}
                    helperText={disableReasonField && 'Check one or more processes to change the state'}
                  />

                  <LabeledTextField
                    label="Reason for Change"
                    value={newReason ?? ''}
                    disabled={!selectedRunMode || isSubmitting}
                    size="small"
                    fullWidth
                    onChange={setNewReason}
                    helperText={!disableReasonField && !selectedRunMode && 'Select a new process state'}
                  />
                </ReasonFieldsContainer>

                <span css={submitButtonStyle}>
                  <SubmitButton
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={disableReasonField || !newReason?.trim() || isSubmitting}
                  >
                    {isSubmitting ? <LoadingSpinner size={theme.typography.fontSize} /> : 'Update'}
                  </SubmitButton>
                  <SubmitButton
                    size="small"
                    variant="outlined"
                    onClick={handleResetRunModeChange}
                    disabled={disableReasonField || isSubmitting}
                  >
                    Reset
                  </SubmitButton>
                </span>
              </RunModeSelectContainer>
            </form>
          }
        </Paper>
      </Stack>
    );
  }
);

export default ProcessStateTable;

const formStyle = css`
  border-color: ${grey[700]};
  border-radius: 5px;
`;

const RunModeSelectContainer = styled('div')(
  ({ theme }) => css`
    /* Stack fields when small */
    ${theme.breakpoints.down('md')} {
      display: flex;
      gap: 2rem;
      flex-direction: column;

      padding: 0.5rem 2rem;
      padding-bottom: 1rem;
    }

    /* Render in row otherwise */
    ${theme.breakpoints.up('md')} {
      display: grid;
      column-gap: 1rem;
      justify-content: center;
      grid-template-columns: 1fr 1fr;

      padding: 0.5rem 15rem;
      padding-bottom: 1rem;
    }
  `
);

const ReasonFieldsContainer = styled('div')(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 300px;
    }
  `
);

const SubmitButton = styled(Button)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 250px;
    }
  `
);

const submitButtonStyle = css`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  padding: 0;
`;

const HeadCells: readonly HeadCell<ProcessStateTableData>[] = [
  {
    id: 'processType',
    numeric: false,
    disablePadding: true,
    label: 'Process Type',
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'State',
  },
  {
    id: 'changedOn',
    numeric: false,
    disablePadding: false,
    label: 'Changed On',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Reason for Change',
  },
];
