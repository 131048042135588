import { useRef } from 'react';

import _ from 'lodash';
import { DateTime } from 'luxon';

import Filters from 'models/Filters';
import { PipelineTimeline } from 'models/Timeline';

import { FilterQueryParams, PastDaysFieldEndDateOffset, ProcessStatus, RunMode } from './constants';
import { DateRange, PipelineIdentifier } from './types';

export function deepcopy(obj: Record<string, any>) {
  return JSON.parse(JSON.stringify(obj));
}

/** Create an inverted object, where the keys become the values and vice versa.  */
export function invertRecord<Key extends string | number, Value extends string | number>(
  record: Record<Key, Value>
): Record<Value, Key> {
  const invertedRecord: Record<Value, Key> = {} as Record<Value, Key>;

  for (const key in record) {
    const value = record[key];
    invertedRecord[value] = key;
  }

  return invertedRecord;
}

export function generatePipelineInfoFromId(
  pipelineId: Partial<PipelineIdentifier>
): Partial<PipelineIdentifier> & { pipelineName?: string; location?: string } {
  const { process_name: processName, site, facility } = pipelineId;
  const pipelineName = site && processName && facility && [site, processName, facility].join('');
  const location = (site || facility) && `${site?.toUpperCase()} ${facility}`.trim();

  return { pipelineName, location, ...pipelineId };
}

/**
 * Validate that an object contains the minimal requirements for a `PipelineIdentifier`
 * @todo Check for `processType` once it's made required property of `PipelineIdentifier`
 *  */
export function validatePipelineId(pipelineId: Partial<PipelineIdentifier>) {
  const { process_name, site, facility } = pipelineId;
  return process_name && site && facility;
}

/**
 * Generate a processing ID for a given pipeline for regular (non-reprocessing) run
 * Sample for pipeline twrmr @ SGP C1: 'regular_twrmr_sgp_c1'
 */
export function generateRegularProcessingId(pipelineId: PipelineIdentifier): string {
  const { process_name: processName, site, facility } = pipelineId;
  const generatedProcessingId = ['regular', processName, site, facility].join('_');
  return generatedProcessingId;
}

export function generateSiteAndFacilityFromLocation(locationName: string) {
  const splitName = locationName.split(' ');
  const site = splitName.at(0)?.toLocaleLowerCase();
  const facility = splitName.at(1);
  return { site, facility };
}

export function generatePipelineStandardModalTitle(pipelineId: PipelineIdentifier) {
  const { process_name: processName, site, facility } = pipelineId;
  const location = `${site.toUpperCase()} ${facility}`;
  return `${processName} @ ${location}`;
}

export const checkStatusMask = <T extends ProcessStatus>(
  status: number,
  mask: T,
  filters?: Set<T>
): boolean | number => {
  if (filters) {
    return status & mask && filters.has(mask);
  } else return status & mask;
};

export function createFilterURL(
  baseUrl: string,
  startDate: DateTime | null,
  endDate: DateTime | null,
  filterQuery?: string
): string {
  const searchParams = new URLSearchParams();
  if (startDate) searchParams.set(FilterQueryParams.START_DATE, startDate.toISODate());
  if (endDate) searchParams.set(FilterQueryParams.END_DATE, endDate.toISODate());
  if (filterQuery) searchParams.set(FilterQueryParams.FILTER, filterQuery);

  const urlQuery: string = searchParams.toString();
  return urlQuery ? `${baseUrl}?${urlQuery}` : baseUrl;
}

export function EncodeBase64(text: string): string {
  const text_base64: string = window.btoa(unescape(encodeURIComponent(text)));
  return text_base64;
}

export function DecodeBase64(base64Text: string): string {
  const decodedText: string = window.atob(base64Text);
  return decodedText;
}

export function createLocationString(site: string, facility: string): string {
  return `${site} ${facility}`;
}

export function toISOStringDateOnly(date: Date): string {
  // return date.toISOString().split('T')[0];

  const luxDate = DateTime.fromJSDate(date);
  return luxDate.toISODate();
}

export function toISOStringDateOnlyUTC(date: Date): string {
  // return date.toISOString().split('T')[0];
  const luxDate = DateTime.fromJSDate(date).toUTC();
  return luxDate.toISODate();
}

export function getDateFromOffset(startDate: DateTime, offset: number): DateTime {
  return startDate.plus({ days: offset });
}

export function getOffsetFromDate(startDate: DateTime, endDate: DateTime, startColumnIndex: number = 0): number {
  // const start = startDate.getTime();
  // const end = endDate.getTime();

  const startLux = startDate;
  const endLux = endDate;
  const numDays = endLux.diff(startLux, 'days').days;

  // console.log(`numDays: ${Math.round(numDays)}`);

  // return Math.round((end - start) / (1000 * 60 * 60 * 24));
  return Math.round(numDays) + startColumnIndex;
}

export function filterSitesFromLocations(locations: string[]): string[] {
  return locations.filter((location) => location.split(' ').length > 1);
}

// Snippet imported from the following: https://dev.to/bytebodger/constructors-in-functional-components-with-hooks-280m
export const useSingleton = (constructorCallback = () => {}) => {
  const isExecuted = useRef(false);
  if (isExecuted.current) return;
  else {
    constructorCallback();
    isExecuted.current = true;
  }
};

export function getDocumentHeadMetaValue(name: string) {
  const elem = document.head.querySelector(`meta[name="${name}"]`);
  if (!elem) {
    return null;
  }
  return elem.getAttribute('content');
}

export function roundTwoDecimals(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function generateDefaultConfDirectory(processName: string | null, locationName: string | null) {
  const [site, facility] = locationName ? locationName.split(' ') : [null, null];
  const siteLower = site ? site?.toLowerCase() : '[site]';
  const facUpper = facility ? facility?.toUpperCase() : '[FACILITY]';
  const confDirName = `/data/conf/${siteLower}/${siteLower}${processName ?? '[process_name]'}${facUpper}`;

  return confDirName;
}

export function hasNoWriteAccess(pipelineInfo: Pick<PipelineTimeline, 'has_conf' | 'mode'>) {
  const { mode, has_conf } = pipelineInfo;

  return pipelineInfo && (!has_conf || mode === RunMode.AUTO || mode === RunMode.LOCKED);
}

export function computeMaxZoomRequired(containerWidth: number, cellWidth: number, numCells: number) {
  return containerWidth / (cellWidth * numCells);
}

export function computeMinimumTimelineWidth(zoomFactor: number, baseCellWidth: number, numCells: number) {
  return zoomFactor * baseCellWidth * numCells;
}

export function isLastNumDaysAligned(
  lastNumDays: Filters['lastNumDays'],
  startDate: Filters['timelineStartDate'],
  endDate: Filters['timelineEndDate']
): boolean {
  if (!lastNumDays) return false;

  // Use tomorrow as end-date since it's the offset used by PastDaysField
  const tomorrow = DateTime.now().startOf('day').plus({ days: PastDaysFieldEndDateOffset });
  const daysAgoDate = tomorrow.minus({ days: lastNumDays });

  return endDate?.toISODate() === tomorrow.toISODate() && startDate?.toISODate() === daysAgoDate.toISODate();
}

export function getLastNumDaysDates(numDays: number): DateRange {
  const validNumber = !_.isNaN(numDays);
  const today = validNumber ? DateTime.now().startOf('day').plus({ days: PastDaysFieldEndDateOffset }) : null; // add +1 day to include current day (UTC as usual)
  const newStart = validNumber && today ? today.minus({ days: numDays }) : null;
  return { begin: newStart, end: today };
}
