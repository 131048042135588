import React from 'react';

import { observer } from 'mobx-react';

import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useTheme } from '@mui/styles';

import { useServices } from 'services';

import { TextPopover } from 'components/common/TextPopover';
import { AnchorPoints } from 'utils/constants';

import { useStyles } from '../definitions/styles';
import { FilterFieldProps, TextFieldAutocomplete } from '../definitions/types';

export const RanByUserFilter = observer((props: FilterFieldProps<TextFieldAutocomplete['onChange']>) => {
  const { onChange } = props;
  const { authService, processingService } = useServices();
  const classes = useStyles(props);
  const theme = useTheme();
  const filters = processingService.currentModeFilters;
  const isLoggedIn = !!authService.accountDetails;
  const labelName = 'Ran By';
  const disablePopover = !isLoggedIn;

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <TextPopover
        trigger={!disablePopover ? 'none' : 'hover'}
        popoverId="user-filter-popover"
        popoverText={`Filtering by '${labelName}' requires login`}
        popoverPosition={AnchorPoints['Left']}
        paperStyleOverride={{
          padding: theme.spacing(1, 2),
        }}
        text={
          <Autocomplete
            multiple
            disabled={disablePopover}
            value={Array.from(filters.user)}
            onChange={onChange}
            options={processingService.users}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${labelName} ${disablePopover ? '(disabled)' : ''}`}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        }
      />
    </FormControl>
  );
});
