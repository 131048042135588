import { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { FeatureFlag } from 'utils/constants';

import { useFeatureFlag } from './useFeatureFlag';

export interface FeatureWrapperProps {
  featureId: FeatureFlag;
  children?: ReactNode;
  fallbackComponent?: ReactNode;
}

export const FeatureWrapper = observer(({ featureId, children, fallbackComponent = null }: FeatureWrapperProps) => {
  const { flagEnabled } = useFeatureFlag({ featureId });

  if (!flagEnabled) {
    return <>{fallbackComponent}</>;
  }

  return <>{children}</>;
});
